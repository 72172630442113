import React, { Fragment } from 'react';
import createIconComponent from './createIconComponent';

const IconFilterNews = createIconComponent({
	content: (
		<fragment>
			<g transform="translate(-455.000000, -48.000000)">
				<g transform="translate(451.000000, 44.000000)">
					<g>
						<path d="M32,32H8V8h24V32z M9,31h22V9H9V31z"></path>
						<path d="M17,17h-5v-5h5V17z M13,16h3v-3h-3V16z"></path>
						<polygon points="19,13 28,13 28,14 19,14"></polygon>
						<rect x="12" y="20" width="16" height="1"></rect>
						<rect x="12" y="27" width="10" height="1"></rect>
					</g>
				</g>
			</g>
		</fragment>
	),
	hauteur : 32,
	width : 32
}) ;

IconFilterNews.displayName = 'IconFilterNews' ;

export default IconFilterNews ;
