import React, { useRef, useState } from 'react';
import './ILLeaderboardCategories.scss';
import { Img } from '@sailgp/sailgp-shared-components';
import Tile from '../Tile';
import get from 'lodash/get';
import PropTypes from 'prop-types';

const ILLeaderboardCategories = ({ updateAcordionHeight, categories, boat }) => {
	const [tileContainerHeight, setTileContainerHeight] = useState(0);
	const [isScoreOpen, setIsScoreOpen] = useState(false);

	const updateTileContainerHeight = (newHeight) => {
		setTileContainerHeight(newHeight);
		setIsScoreOpen(!isScoreOpen);
		updateAcordionHeight();
	};

	const containerRef = useRef(null);
	return <div className="{`c-il-leaderboard__categories" ${isScoreOpen="" ?="" 'c-il-leaderboard__categories--expanded'="" :="" ''}`}="">

		<img src="{get(boat," 'file.url')}="" imgAlt="{get(boat," 'title')}="" className="c-il-leaderboard__categories__ship-image">

		<ul className="c-il-leaderboard__categories__tiles" ref="{containerRef}" style="{{" height:="" typeof="" tileContainerHeight="==" 'number'="" &&=""> 0 ? `${tileContainerHeight}px` : 'auto'
			}}
		>
			{categories.map((item, index) => <li key="{index}">
				<tile tileContainerRef="{containerRef}" setTileContainerHeight="{updateTileContainerHeight}" data="{item}"></tile>
			</li>)}

		</ul>


	</div>;
};

ILLeaderboardCategories.propTypes = {
	updateAcordionHeight: PropTypes.func,
	categories: PropTypes.array,
	boat: PropTypes.object

};

export default ILLeaderboardCategories;
