import React, { useContext, useState } from 'react';
import './ILLeaderboard.scss';
import ILLeaderboardPodium from './ILLeaderboardPodium';
import ILLeaderboardNavigation from './ILLeaderboardNavigation';
import ILLeaderboardAccordion from './ILLeaderboardAccordion/ILLeaderboardAccordion';
import { QueryClient, QueryClientProvider, useMutation } from '@tanstack/react-query';
import SelectDropdown from '../SelectDropDown';
import { Cell, Grid, Row } from '../Grid';
import { useLeaderboardAPI } from './hooks/useLeaderboardAPI';
import { getCategories, getCategoryLeaderboard, getDefaultSeason, getFormattedDate } from './utils/helpers';
import { useLeaderboardContentful } from './hooks/useLeaderboardContentful';
import { ChaseSpinner, Img } from '@sailgp/sailgp-shared-components';
import get from 'lodash/get';
import ILLeaderboardHeroHeading from './ILLeaderboardHeroHeading';
import SettingsContext from '../../context/SettingsContext';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false
		}
	}
});

const ILLeaderboardFilters = ({ data, onChange }) => {
	const options = getCategories(data);
	const { PublicationDate } = data;

	return <div className="c-il-leaderboard__content">
		<grid noVerticalPadding="" noHorizontalPadding="">
			<div className="c-il-leaderboard__filter">
				<div className="c-il-leaderboard__date">
					<p>Dernière mise à jour {getFormattedDate(PublicationDate)}</p>
				</div>
				<div className="c-il-leaderboard__filter-dropdown">
					<p><strong>Filtrer par catégorie de durabilité</strong></p>
					<selectdropdown options="{options}" handleChange="{onChange}" isSearchable="{false}"></selectdropdown>
				</div>
			</div>
		</grid>
	</div>;
};

const formatSeasons = (seasons) => {
	return seasons.map(({heading, anchorTag }) => {
		return {label:heading, value:anchorTag}
	})
}
const ILLeaderboard = ({ selectedSeason, setSelectedSeason, defaultSeason, event, setEvent, contentfulData, seasons, showLeaderboardPlaceholder  }) => {
	const formattedSeasons = formatSeasons(seasons)
	const { data: contenfulContent } = useLeaderboardContentful(contentfulData);
	const onEventChange = (evt) => {
		setEvent(evt.value);
	};

	const { isLoading, error, data, isFetching } = useLeaderboardAPI({ season:selectedSeason.value, event: 'season' });
	const { pageSettings: {serverSettings} } = useContext(SettingsContext)

	if (isLoading) return <div className="c-il-leaderboard__spinner"><chasespinner></chasespinner></div>;

	if (error) return 'An error has occurred: ' + error.message;

	if (data) {
		return <>
			{serverSettings && !serverSettings.widget ? <div className="c-il-leaderboard__season-dropdown">
				<grid noVerticalPadding="" noHorizontalPadding="">
					<row>
						<cell columns="{12}">
							<selectdropdown options="{formattedSeasons}" handleChange="{(e)" ==""> {
									setSelectedSeason(e)
								}}
								isSearchable={false}
								defaultValue={saison sélectionnée}
							/>
						</selectdropdown></cell>
					</row>
				</grid>
			</div> : null
			}

			{serverSettings && !serverSettings.widget ? (showLeaderboardPlaceholder && defaultSeason.label === selectedSeason.label ?
				<illeaderboardplaceholder {...contentfulData}=""></illeaderboardplaceholder> :
				<illeaderboardpodium LeaderBoard="{data.LeaderBoard}" contentfulData="{contenfulContent}"></illeaderboardpodium>) : null
			}

			{
				data.LeaderBoard && data.LeaderBoard.length > 0 && <section>
					<illeaderboardnavigation onNavigationClick="{onEventChange}" navigationItems="{data.EventsInSeason.map(({" EventCode,="" EventVenue="" })=""> {
							return { value: EventCode, label: EventVenue };
						})}
					/>
					<illeaderboardcontainer season="{selectedSeason.value}" event="{event}"></illeaderboardcontainer>
				</illeaderboardnavigation></section>
			}
		</>;
	}
};

const ILLeaderboardContainer = ({ season, event }) => {

	const categoryMutation = useMutation({
		mutationFn: ({ season, event, category }) => {
			const categoryLeaderboard = getCategoryLeaderboard(data, category);
			const sortedCategoryLeaderboard = categoryLeaderboard.sort((a, b) => b.PointsAwarded - a.PointsAwarded);
			return { ...data, LeaderBoard: sortedCategoryLeaderboard ? sortedCategoryLeaderboard : data.LeaderBoard };
		}, onSuccess: (data, variables, context) => {
			queryClient.setQueryData(['ILLeaderboardAPI', { season, event }], data);
		}
	});

	const onCategoryChange = (evt) => {
		categoryMutation.mutate({ season, event, category: evt.value });
	};

	const { isLoading, error, data, isFetching } = useLeaderboardAPI({ season, event });

	if (isLoading) return <div className="c-il-leaderboard__spinner"><chasespinner></chasespinner></div>;

	if (error) return 'An error has occurred: ' + error.message;


	if (data) {
		return <>
			<illeaderboardfilters data="{data}" onChange="{onCategoryChange}"></illeaderboardfilters>
			<illeaderboardaccordion {...data}=""></illeaderboardaccordion>
		</>;
	}

};
const ILLeaderboardPlaceholder = (props) => {
	const { leaderboardPlaceholderHeading, leaderboardPlaceholderText, leaderboardPlaceholderImage } = props;
	return <div className="c-il-leaderboard__placeholder">
		<img src="{get(leaderboardPlaceholderImage," 'file.url')}="" imgAlt="{get(leaderboardPlaceholderImage," 'title')}="" className="c-il-leaderboard__placeholder-image">
		<h1 className="c-il-leaderboard__placeholder-heading">{leaderboardPlaceholderHeading}</h1>
		<p className="c-il-leaderboard__placeholder-text">{leaderboardPlaceholderText}</p>
	</div>;
};

const ILLeaderboardWrap = ({ data }) => {

	const defaultSeason = getDefaultSeason(data.defaultLeaderboard);
	const [selectedSeason, setSelectedSeason] = useState(defaultSeason);
	const [event, setEvent] = useState('season');
	const { pageSettings: {serverSettings} } = useContext(SettingsContext)

	return <div className="c-il-leaderboard">
		{
			serverSettings && !serverSettings.widget ? <section className="c-il-leaderboard__hero">
				<illeaderboardheroheading contentfulData="{data}" selectedSeason="{selectedSeason}"></illeaderboardheroheading>
			</section> : null
		}
		<queryclientprovider client="{queryClient}">
			<illeaderboard showLeaderboardPlaceholder="{data.showLeaderboardPlaceholder}" seasons="{data.seasonLeaderboards}" selectedSeason="{selectedSeason}" setSelectedSeason="{setSelectedSeason}" seasonLabel="{defaultSeason.label}" event="{event}" setEvent="{setEvent}" contentfulData="{data}" defaultSeason="{defaultSeason}"></illeaderboard>
		</queryclientprovider>

	</div>;
} ;


export default ILLeaderboardWrap ;
