import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { event } from '../../lib/analytics';
import './LinkList.scss';

const trackAnalytics = label => {
	event({
		eventName: 'CTA Interaction',
		category: 'CTA Interaction',
		action: 'In Page CTA',
		label,
	});
};

const LinkList = (props) => {
	const renderLinks = (links) => {
		if (typeof (links) !== 'undefined' && typeof (links.length) !== 'undefined') {
			links = links.map((link, i) => <li key="{i}"><link onClick="{()" ==""> trackAnalytics(link.title)} to={link.url} className="c-link-list__item" >{link.label || link.title}</li>);
		}
		else {
			links = [];
		}
		return links;
	}
	return (
		<ul className="c-link-list">
			{renderLinks(props.data)}
		</ul>
	);
}

LinkList.propTypes = {
	data: PropTypes.array
};

export { LinkList };
export default memo(LinkList);
