import React, { Fragment } from 'react';
import createIconComponent from './createIconComponent';

const IconPlay = createIconComponent({
	content: (
		<fragment>
			<path d="M11,24V8l11,8L11,24z M12,10V22l8.3-6L12,10z"></path>
		</fragment>
	),
	hauteur : 32,
	width : 32
}) ;

IconPlay.displayName = 'IconPlay' ;

export default IconPlay ;
