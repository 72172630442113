import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Cell } from '../Grid';
import Card from '../Card';
import './Category.scss';
import SelectDropdown from '../SelectDropDown';

export default function Category(props) {
	const { data } = props;
	const [getCardData, setCardData] = useState(data.categorySeasons[0]);
	const options =
		data.categorySeasons &&
		data.categorySeasons.length > 0 &&
		data.categorySeasons.map(category => {
			return {
				value: category.season,
				label: category.season
			};
		});
	const seasonChange = event => {
		setCardData(
			data.categorySeasons.find(category => {
				return category.season === event.label;
			})
		);
	};
	return (
		<react.fragment>
			{getCardData && Object.keys(getCardData).length > 0 ? (
				<section className="categories" id="categories">
					<grid className="score-categories" noHorizontalPadding="" noVerticalPadding="">
						<row className="score-categories-section">
							<cell xlCols="{9}" lgCols="{9}" smCols="{2}" mdCols="{6}" className="header">
								<h3 className="category-heading">{data && data.heading ? data.heading : ''}</h3>
								<p className="category-desc">{data && data.description ? data.description : ''}</p>
							</cell>
							<cell xlCols="{3}" lgCols="{3}" smCols="{2}" mdCols="{6}" className="select-season">
								{options && options.length > 0 ? <selectdropdown options="{options}" handleChange="{seasonChange}"></selectdropdown> : null}
							</cell>
						</row>
						
						{getCardData.seasonCategoryCards && getCardData.seasonCategoryCards.length > 0 ? (
							<row className="score-categories-card">
								<cell xlCols="{12}" lgCols="{12}" smCols="{2}" mdCols="{6}">
									<card data="{getCardData.seasonCategoryCards}"></card>
								</cell>
							</row>
						) : null}
					</grid>
				</section>
			) : null}
		</react.fragment>
	);
}

Category.propTypes = {
	data: PropTypes.object
};

Category.defaultProps = {
	data: {}
};
