import React, { useEffect, useState, memo, useContext } from 'react';
import './NearWallet.scss'
import PropTypes from 'prop-types';
import DockCTA from '../DockCTA/DockCTA';
import {MyWallet} from './mynear-wallet';
import {Button} from '@sailgp/sailgp-shared-components';
import { submitDockForm, submitDockFormNoBody} from '../../utils/submitDockForm';
import PassphraseGrid from '../PassphraseGrid/PassphraseGrid';
import { toast } from 'react-toastify';
import SettingsContext from '../../context/SettingsContext';
import FormGroup from '../FormGroup';

const NearWallet = (props) => {
	const { pageSettings } = useContext(SettingsContext);
	const {nearEnvironmentKeys} = pageSettings;

	const [mywallet,setmywallet] = useState(false);
	const [urlAccountData,setUrlData] = useState(false);

	const initWallet =  async ()  => {

		let success = props.data['successUrl'];
		let failure = props.data['failureUrl'];

		const createAccount =  nearEnvironmentKeys.NEAR_MASTER_ACCOUNT;
		const networkId =  nearEnvironmentKeys.NEAR_NETWORK_ID;

		const myNearWallet =  new MyWallet({ createAccessKeyFor: createAccount, network:networkId ,successUrl:success, failureUrl:failure, nearEnvironmentKeys })
		myNearWallet.startUp();
		setmywallet(myNearWallet);

	};

	useEffect(() => {
		if(urlAccountData !== false){
			linkWalletSubmit();
		}
	  }, [urlAccountData]);

	const linkWalletSubmit =  async ()  => {
        let response= await submitDockForm(urlAccountData, props.data['linkWalletUrl']);
        if(response["status"] !== undefined && response["status"] == "success"){
            toast.success( props.data['successMessage'], {
              position: toast.POSITION.TOP_CENTER
            });
        }else if(response["status"] !== undefined && response["status"] == "error" && response["errorDescription"] != undefined){
            toast.error( response["errorDescription"], {
				autoClose: false,
              position: toast.POSITION.TOP_CENTER
            });
        }else {
            toast.error("Server Error !", {
				autoClose: false,
                position: toast.POSITION.TOP_CENTER
            });
        }
    }
	const fetchUrlData =  async ()  => {

		const queryParams  = new URLSearchParams(window.location.search);
		let accountId = queryParams.get("account_id");
		let publicKey = queryParams.get("public_key");

		if(accountId !== null && publicKey!==null){
			let paramData = {
				accountID : accountId,
				publicKey : publicKey
			};
			setUrlData(paramData)
		}
	};

	useEffect(() => {

		if(props.data['linkWalletUrl'] !== undefined)
			fetchUrlData();


		if(props.data['isConnectVisible']){
			initWallet();
		}

	}, []);

	const handleClickMyNear =  async ()  => {
		mywallet.signIn();
	};

	const handleClickCancel =  ()  => {
		// TODO: modal instead of redirect
		location.assign(props.data['cancelUrl']);
	};

	const handleClickPrimaryLink = () => {
		location.assign(props.data['successUrl']);

	};

	const handleClickPrimaryLinkNonCustodial = async () => {
		const response = await submitDockFormNoBody(props.data['linkWalletUrl']);

		if(response["status"] !== undefined && response["status"] == "success"){
			localStorage.setItem("unlinkAccount",true);
			location.assign(props.data['successUrl']);
		}
		else if(response["status"] !== undefined && response["status"] == "error" && response["redirect"] == true){
			toast.error( "Login expired", {
				autoClose: false,
			  position: toast.POSITION.TOP_CENTER
			});
			logOut();

		  }
		  else if(response["status"] !== undefined && response["status"] == "error"){
			  toast.error( "There is an error", {
				autoClose: false,
			  position: toast.POSITION.TOP_CENTER
			});
		}


	};

	const getWalletContent = () => {
		switch (props.data.nearWalletPageName) {
			case 'near-busy':
			case 'near-connection-failed':
			case 'near-connect':
				return <>
					<div className="near-wallet-btn-wrapper">
						<button type="submit" className="btn-primary" onClick="{handleClickMyNear}">
							{props.data['actionButtonLabel']}
						</button>
						<button type="button" className="btn-secondary" onClick="{handleClickCancel}">
							{props.data['cancelButtonLabel']}
						</button>
					</div>
				</>;
			case 'near-confirmation':
				return <div className="near-wallet-btn-wrapper">
					<dockcta type="submit" onClick="{handleClickPrimaryLink}" label="{props.data['actionButtonLabel']}"></dockcta>
				</div>;
			case 'near-link-wallet' :
				// TODO :
				retourner '' ;
			case 'near-unlink-account-non-custodial' :
					retour <div className="near-wallet-btn-wrapper">
				<dockcta type="submit" onClick="{handleClickPrimaryLinkNonCustodial}" label="{props.data['actionButtonLabel']}"></dockcta>
				<button type="button" className="link-secondary" href="{props.data['cancelUrl']}">
							{props.data['cancelButtonLabel']}
						</button>
				</div>;

			case 'near-passphrase':
				return <>
					<passphrasegrid {...props}=""></passphrasegrid>
					<div className="near-wallet-btn-wrapper vertical-wrapper">
						<dockcta type="submit" onClick="{handleClickPrimaryLink}" label="{props.data['actionButtonLabel']}"></dockcta>
						<button type="button" className="link-secondary" href="{props.data['cancelUrl']}">
							{props.data['cancelButtonLabel']}
						</button>
					</div>
				</>;

			default:
				return '';
		}
	};

	return getWalletContent();

};

NearWallet.propTypes = {
	history: PropTypes.object,
	data: PropTypes.any
};

export default memo(NearWallet);
