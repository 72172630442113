import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import './RaceEvent.scss';
import RaceSchedule from '../RaceSchedule/containers/RaceScheduleItemContainer';
import LiveFeed from '../LiveFeed';
import {SectionTabs} from '@sailgp/sailgp-shared-components';
import PageContext from '../../context/PageContext';
import Standings from './Standings';

class RaceEvent extends Component {
	constructor(props) {
		super(props);
		const { data } = this.props;

		this.tabs = [
			{
				id: 1,
				title: data.liveFeedLabel,
				visible: get(data, 'liveFeed.liveFeed', []).length > 0,
				component:
				<pagecontext.consumer>
					{({ activeLanguage }) => (
						<livefeed heading="{get(data," 'liveFeed.heading',="" '')}="" summary="{get(data," 'liveFeed.summary',="" [])}="" liveFeed="{get(data," 'liveFeed.liveFeed',="" itemsPerPage="{get(data," 'liveFeed.itemsPerPage',="" 2)}="" websiteUrl="{data.websiteUrl}" contentfulId="{data.contentfulId}" liveFeedLabel="{data.liveFeedLabel}" summaryLabel="{data.summaryLabel}" activeLanguage="{activeLanguage}" newItemLabel="{data.newItemLabel}" newItemsLabel="{data.newItemsLabel}" loadMoreLabel="{data.loadMoreLabel}"></livefeed>
					)}
				</pagecontext.consumer>	,
			},
			{
				id: 2,
				title: data.eventInformationLabel,
				visible: true,
				component:
					<raceschedule eventHeading="{data.eventHeading}" eventIntroImage="{data.eventIntroImage}" links="{data.links" ?="" data.links.links="" :="" []}="" partners="{data.partners}" raceDays="{data.raceDays}" startDateTime="{data.startDateTime}" wonByLabel="{data.wonByLabel}" presentedByLabel="{data.presentedByLabel}" locale="{data.acceptLanguage}" leaderboardInfo="{data.leaderboardInfo}"></raceschedule>,
			},
			{
				id: 3,
				title: data.standingsLabel,
				visible: get(data, 'standings', []).length > 0,
				component:
					<standings data="{data.standings}"></standings>,
			}
		];
	}

	render() {
		return (
			<sectiontabs tabs="{this.tabs}"></sectiontabs>
		);
	}
}

RaceEvent.propTypes = {
	data: PropTypes.object.isRequired,
}

export default RaceEvent;
