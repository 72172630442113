import React from 'react';
import './ILTeamMatchupHeading.scss';
import { Cell, Grid, Row } from '../../Grid';
import { Img } from '@sailgp/sailgp-shared-components';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import Link from '../../Link';
import IconDoubleArrowsLeft from '../../Icons/DoubleArrowsLeft';
import SelectDropdown from '../../SelectDropDown';

const ILTeamMatchupHeading = ({ contentfulData, season, seasonsOptions, onSeasonChange }) => {
	if (!contentfulData) return null;
	const { headingTextFilled, headingTextStroked, backLink, backLinkText } = contentfulData;
	return <div>
		<grid noHorizontalPadding="" noVerticalPadding="">
			<row>
				<cell mdCols="{4}" lgCols="{9}" xlCols="{9}" align="middle">

					{
						backLinkText && backLink &&
						<link className="c-il-team-matchup__back" to="{backLink}">
							<icondoublearrowsleft></icondoublearrowsleft>
							<span className="c-il-team-matchup__back-text">{backLinkText}</span>
						
					}

					<div className="c-il-team-matchup__hero-heading-container">
						<h1 className="c-il-team-matchup__hero-heading">
							<span className="c-il-team-matchup__hero-heading--stroke">{headingTextStroked}</span> 
							<span className="c-il-team-matchup__hero-heading--filled">{headingTextFilled}</span>
						</h1>
						<selectdropdown defaultValue="{season}" options="{seasonsOptions}" handleChange="{onSeasonChange}" isSearchable="{false}" className="c-il-dropdown c-il-dropdown--italic"></selectdropdown>
					</div>

				</cell>
				<cell mdCols="{2}" lgCols="{3}" xlCols="{3}" align="middle" className="c-il-team-matchup__hero-image">
					<img src="{get(contentfulData.headingImage," 'file.url')}="" imgAlt="{get(contentfulData.headingImage," 'title')}="">
				</cell>
			</row>
		</grid>
	</div>;


};


ILTeamMatchupHeading.propTypes = {
	contentfulData: PropTypes.object,
	headingTextFilled: PropTypes.string,
	headingTextStoked: PropTypes.string,
	headingImage: PropTypes.object,
	backLink: PropTypes.string,
	seasonsOptions: PropTypes.array,
	backLinkText: PropTypes.string,
	season: PropTypes.object
};
export default ILTeamMatchupHeading;
