import React, { Fragment } from 'react';
import createIconComponent from './createIconComponent';

const IconSailGPLogo = createIconComponent({
	content: (
		<fragment>
			<defs>
				<lineargradient id="sailgp_logo_svg__a" x1="55.733%" x2="5.348%" y1="64.045%" y2="-47.861%">
					<stop offset="44.81%" stopColor="#E23B25"></stop>
					<stop offset="52.74%" stopColor="#D63723"></stop>
					<stop offset="67.07%" stopColor="#B62E1E"></stop>
					<stop offset="86.06%" stopColor="#831F15"></stop>
					<stop offset="94.36%" stopColor="#6A1811"></stop>
				</lineargradient>
				<lineargradient id="sailgp_logo_svg__b" x1="57.559%" x2="33.076%" y1="48.393%" y2="-31.934%">
					<stop offset="0%" stopColor="#E23B25"></stop>
					<stop offset="52.74%" stopColor="#D63723"></stop>
					<stop offset="67.07%" stopColor="#B62E1E"></stop>
					<stop offset="86.06%" stopColor="#831F15"></stop>
					<stop offset="100%" stopColor="#6A1811"></stop>
				</lineargradient>
				<lineargradient id="sailgp_logo_svg__c" x1="64.832%" x2=".416%" y1="105.257%" y2="-129.733%">
					<stop offset="42.25%" stopColor="#E23B25"></stop>
					<stop offset="77.95%" stopColor="#6A1811"></stop>
				</lineargradient>
				<lineargradient id="sailgp_logo_svg__d" x1="50.999%" x2="22.177%" y1="83.61%" y2="-166.07%">
					<stop offset="42.25%" stopColor="#E23B25"></stop>
					<stop offset="77.95%" stopColor="#6A1811"></stop>
				</lineargradient>
				<lineargradient id="sailgp_logo_svg__e" x1="46.271%" x2="38.585%" y1="38.973%" y2="-51.895%">
					<stop offset="0%" stopColor="#E23B25"></stop>
					<stop offset="100%" stopColor="#6A1811"></stop>
				</lineargradient>
				<lineargradient id="sailgp_logo_svg__f" x1="55.961%" x2="46.505%" y1="70.467%" y2="-15.322%">
					<stop offset="0%" stopColor="#E23B25"></stop>
					<stop offset="100%" stopColor="#6A1811"></stop>
				</lineargradient>
				<lineargradient id="sailgp_logo_svg__g" x1="38.664%" x2="141.29%" y1="38.11%" y2="145.703%">
					<stop offset="0%" stopColor="#E23B25"></stop>
					<stop offset="100%" stopColor="#6A1811"></stop>
				</lineargradient>
				<lineargradient id="sailgp_logo_svg__h" x1="14.517%" x2="116.538%" y1="12.579%" y2="120.172%">
					<stop offset="0%" stopColor="#E23B25"></stop>
					<stop offset="100%" stopColor="#6A1811"></stop>
				</lineargradient>
				<lineargradient id="sailgp_logo_svg__i" x1="26.051%" x2="159.267%" y1="12.952%" y2="288.265%">
					<stop offset="31.48%" stopColor="#E23B25"></stop>
					<stop offset="70.56%" stopColor="#6A1811"></stop>
				</lineargradient>
				<lineargradient id="sailgp_logo_svg__j" x1="62.375%" x2="189.089%" y1="77.997%" y2="344.894%">
					<stop offset="31.48%" stopColor="#E23B25"></stop>
					<stop offset="70.56%" stopColor="#6A1811"></stop>
				</lineargradient>
				<lineargradient id="sailgp_logo_svg__k" x1="34.12%" x2="63.184%" y1="13.86%" y2="129.093%">
					<stop offset="0%" stopColor="#E23B25"></stop>
					<stop offset="61.4%" stopColor="#C83321"></stop>
					<stop offset="89.01%" stopColor="#862016"></stop>
					<stop offset="100%" stopColor="#6A1811"></stop>
				</lineargradient>
				<lineargradient id="sailgp_logo_svg__l" x1="34.592%" x2="80.92%" y1="26.782%" y2="168.025%">
					<stop offset="48.4%" stopColor="#E23B25"></stop>
					<stop offset="61.4%" stopColor="#C83321"></stop>
					<stop offset="89.01%" stopColor="#862016"></stop>
					<stop offset="100%" stopColor="#6A1811"></stop>
				</lineargradient>
			</defs>
			<g fill="none" fillRule="evenodd">
				<path fill="url(#sailgp_logo_svg__a)" d="M149.206 7.292l-.125-.247-5.428.921-.068.292-1.715.293c.237.595.316 1.303.17 2.078-.249 1.382-.825 2.292-2.122 2.978-1.197.64-2.72.82-5.124.82h-2.99l1.094-4.37.045-.304-5.552.899-.09.348-1.727.292-4.526 18.101h7.042l2.325-9.292h5.022c4.142 0 7.144-.652 9.266-2.045 2.72-1.798 4.017-3.921 4.559-7.056.214-1.315.192-2.573-.056-3.708z"></path>
				<path fill="url(#sailgp_logo_svg__b)" d="M108.284 14.528l-1.32 5.382h6.67c-1.366 2.652-3.871 4.135-7.19 4.135-4.57 0-6.59-3.63-5.98-8.472l.022-.315-5.744 1.023-.08.27-1.726.292c-.496 7.258 3.623 13.112 11.432 13.112 3.68 0 6.636-1.168 8.442-3.034l-.598 2.483h5.902l3.702-14.876h-13.532z"></path>
				<path fill="url(#sailgp_logo_svg__c)" d="M55.22 29.404h7.03l2.777-11.1.011-.26-5.281-.246-.373.505h-1.388z"></path>
				<path fill="url(#sailgp_logo_svg__d)" d="M73.65 23.73l1.365-5.427v-.27h-5.598l-.068.27h-1.376l-2.788 11.101h21.431l1.41-5.674z"></path>
				<path fill="url(#sailgp_logo_svg__e)" d="M29.963 18.303l-7.065 11.101h7.799l3.712-6.146h11.444l.767 6.146h7.268l-1.726-11.1V18.1H31.25l-.136.202z"></path>
				<path fill="url(#sailgp_logo_svg__f)" d="M24.4 18.303l-.023-.09H14.31l.102.09c-.034-.01-.057-.022 0 0 2.55.776 3.272 1.877 3.035 3.573-.248 1.73-2.087 2.72-4.74 2.72-3.724 0-6.59-1.82-6.297-6.124H.034C-.43 25.37 4.232 29.944 12.617 29.944c6.286 0 12.064-2.989 12.064-9.607 0-.741-.101-1.416-.282-2.034z"></path>
				<path fill="url(#sailgp_logo_svg__g)" d="M63.752.843l-4.368 17.46h7.031L70.783.843z"></path>
				<path fill="url(#sailgp_logo_svg__h)" d="M73.717.843l-4.368 17.46h7.054L80.815.843z"></path>
				<path fill="url(#sailgp_logo_svg__i)" fillRule="nonzero" d="M42.23.843l-11.116 17.46h22.47L50.864.843H42.23zM38.97 17.55l5.507-9.124h.948l1.129 9.124h-7.584z"></path>
				<path fill="url(#sailgp_logo_svg__j)" d="M15.777.292C8.633.292 4.03 4.438 4.03 9.944c0 2.989 1.467 4.966 4.446 6.337 1.885.865 4.402 1.63 5.925 2.022h10.947c-.812-2.764-3.397-4.483-7.37-5.595l-3.656-1.09c-1.772-.54-3.205-1.247-3.193-2.831.01-1.461 1.196-3.057 4.31-3.057 4.075 0 6.151 2.528 5.858 5.585h6.308C28.214 4.146 22.797.292 15.777.292z"></path>
				<path fill="url(#sailgp_logo_svg__k)" d="M148.167 2.483c-1.726-1.326-4.108-1.932-7.313-1.932h-10.947l-2.618 10.46 7.324-1.236.892-3.595h3.78c1.558 0 2.54.213 3.409.887a2.8 2.8 0 0 1 .88 1.191L150.921 7c-.384-1.876-1.354-3.45-2.754-4.517z"></path>
				<path fill="url(#sailgp_logo_svg__l)" d="M102.179 15.27c.068-.472.147-.776.26-1.259 1.004-4.348 4.13-8.056 8.52-8.056 2.788 0 4.537 1.303 5.36 3.326 0 .011.012.022.012.022.395 1.023.361 2.214.361 2.214h6.692C123.384 4.674 119.322 0 111.162 0 103.183 0 97.1 5.472 95.171 13.27a20.055 20.055 0 0 0-.52 3.27l5.892-.99 1.636-.28z"></path>
			</g>
		</fragment>
	),
	hauteur : 30,
	width : 151
}) ;

IconSailGPLogo.displayName = 'IconSailGPLogo' ;

export default IconSailGPLogo ;
