import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

class RaceCountdown extends Component {
	constructor(props) {
		super(props);

		this.state = {
			days: 0,
			hours: 0,
			minutes: 0,
			seconds: 0,
		}

		this.calculateCountdown = this.calculateCountdown.bind(this);
		this.stop = this.stop.bind(this);
	}

	componentDidMount() {
		this.interval = setInterval(() => {
			const date = this.calculateCountdown(this.props.eventDate);
			if (date) {
				this.setState({
					days: date.days,
					hours: date.hours,
					minutes: date.minutes,
					seconds: date.seconds,
				})
			} else {
				this.stop();
			}
		}, 1000);
	}

	componentWillUnmount() {
		this.stop();
	}

	calculateCountdown(endDate) {
		let diff = (Date.parse(new Date(endDate)) - Date.parse(new Date())) / 1000;

		// clear countdown when date is reached
		if (diff <= 0) return false;

		const timeLeft = {
			days: 0,
			hours: 0,
			minutes: 0,
			seconds: 0
		};

		// calculate time difference between now and expected date
		if (diff >= 86400) { // 24 * 60 * 60
			timeLeft.days = Math.floor(diff / 86400);
			diff -= timeLeft.days * 86400;
		}

		if (diff >= 3600) { // 60 * 60
			timeLeft.hours = Math.floor(diff / 3600);
			diff -= timeLeft.hours * 3600;
		}

		if (diff >= 60) {
			timeLeft.minutes = Math.floor(diff / 60);
			diff -= timeLeft.minutes * 60;
		}

		timeLeft.seconds = diff;

		return timeLeft;
	}

	stop() {
		clearInterval(this.interval);
	}

	render() {
		const { days, hours, minutes, seconds } = this.state;
		const { dayLabel, daysLabel, hourLabel, hoursLabel, minuteLabel, minutesLabel, secondLabel, secondsLabel } = this.props;

		if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) return null;
		return (
			<fragment>
				{ days }
				{ days === 1 ? ` ${dayLabel}, ` : ` ${daysLabel}, `}
				{ hours }
				{ hours === 1 ? ` ${hourLabel}, ` : ` ${hoursLabel}, `}
				{ minutes }
				{ minutes === 1 ? ` ${minuteLabel}, ` : ` ${minutesLabel}, `}
				{ seconds }
				{ seconds === 1 ? ` ${secondLabel}.` : ` ${secondsLabel}.`}
			</fragment>
		);
	}
}

RaceCountdown.propTypes = {
	eventDate: PropTypes.instanceOf(Date),
	dayLabel: PropTypes.string,
	daysLabel: PropTypes.string,
	hourLabel: PropTypes.string,
	hoursLabel: PropTypes.string,
	minuteLabel: PropTypes.string,
	minutesLabel: PropTypes.string,
	secondLabel: PropTypes.string,
	secondsLabel: PropTypes.string,
};

RaceCountdown.defaultProps = {
	eventDate: new Date(),
	dayLabel: 'day',
	daysLabel: 'days',
	hourLabel: 'hour',
	hoursLabel: 'hours',
	minuteLabel: 'minute',
	minutesLabel: 'minutes',
	secondLabel: 'second',
	secondsLabel: 'seconds',
};

export default RaceCountdown;
