import React, { useEffect, useMemo, useState } from 'react';
import { Cell, Grid, Row } from '../Grid';
import './ILTeamMatchup.scss';
import SelectDropdown from '../SelectDropDown';
import { useLeaderboardAPI } from '../ILLeaderboard/hooks/useLeaderboardAPI';
import ProgressBar from './ILTeamMatchupProgress';
import ILTeamMatchupHeading from './ILTeamMatchupHeading';
import { ChaseSpinner, Img } from '@sailgp/sailgp-shared-components';
import get from 'lodash/get';
import ILTeamMatchupCheckbox from './ILTeamMatchupCheckbox';
import ILLeaderboardNavigation from '../ILLeaderboard/ILLeaderboardNavigation';
import nanoid from 'nanoid';
import { getDefaultSeason, getFormattedDate } from '../ILLeaderboard/utils/helpers';

const ILTeamMatchupContainer = ({ dataContentful, setPrimaryTeam, selectedTeams, setSelectedTeams, primaryTeam }) => {

	const defaultSeason = getDefaultSeason(dataContentful.defaultLeaderboard) || {
		label: 'Season 3',
		value: 'SGP-IL-S03-CHAMP'
	};
	const seasonsOptions = useMemo(() => [defaultSeason], []);
	const [season, setSeason] = useState(seasonsOptions[seasonsOptions.length - 1]);
	const [event, setEvent] = useState('season');
	const [teamsData, setTeamsData] = useState([])
	const { data: leaderboardData } = useLeaderboardAPI({ season:season.value, event: 'season' });

	const getTeamList = () => {
		const options = [{
			value: 'select', label: 'Select team'
		}];
		if(leaderboardData) {
			leaderboardData.LeaderBoard.map(team => {
				options.push({
					value: team.TeamName, label: team.TeamDescription
				});
			});
		}
		return options;
	};
	const teamsDropOptions = getTeamList();
	const [selectedDropdownTeam, setSelectedDropdownTeam] = useState(teamsDropOptions[0]);

	useEffect(() => {
		if (leaderboardData) {
			const teams = leaderboardData.LeaderBoard.map(({TeamName, TeamDescription}) => {
				return {value: TeamName, label: TeamDescription}
			})
			const matchedTeamsData = teams.map(({value}) => {
				return dataContentful.listOfTeams.find(({code}) => code === value)
			})
			setTeamsData(matchedTeamsData)
		}
	}, [leaderboardData])

	useEffect(() => {
		if(leaderboardData) {
			const availableTeams = selectedTeams.filter((team) => {
				return leaderboardData.LeaderBoard.find(({TeamName})=> TeamName === team)
			})
			if(availableTeams.length !== selectedTeams.length) {
				setSelectedTeams(availableTeams)
			}
			const existingPrimaryTeam = leaderboardData.LeaderBoard.find(({TeamName}) => primaryTeam.code === TeamName)
			if(!existingPrimaryTeam) {
				setSelectedDropdownTeam(teamsDropOptions[0]);
				setSelectedTeams([]);
				setPrimaryTeam('');
			}
		}

	}, [leaderboardData])



	const { isLoading, data, error } = useLeaderboardAPI({ season: season.value, event: 'season' });

	if (isLoading) return <div className="c-il-leaderboard__spinner"><chasespinner></chasespinner></div>;

	if (error) return 'An error has occurred: ' + error.message;


	const getNavigationItems = () => {
		const navData = [];
		data.EventsInSeason.map(({ EventCode, EventVenue }) => {
			navData.push({ value: EventCode, label: EventVenue });
		});
		return navData;
	};


	const onSeasonChange = seasonVal => {
		setSeason(seasonVal);
	};

	const TeamsPlaceholder = () => {
		return <div className="matchup-secondary-team">
			<div className="matchup-secondary-team-img">
				<img src="{get(dataContentful.defaultSecondaryImage," 'file.url')}="" imgAlt="{get(dataContentful.defaultSecondaryImage," 'title')}="">
			</div>
		</div>;
	};

	const setPrimaryTeamState = selectedTeam => {
		const selectedTeamDetail = teamsData.filter(team => team.code == selectedTeam);
		setPrimaryTeam(...selectedTeamDetail);
	};

	const onPrimaryTeamChange = team => {
		setSelectedDropdownTeam(team);
		setPrimaryTeamState(team.value);
		setSelectedTeams([]);
	};

	const onSecondaryTeamSelect = team => {
		if (selectedTeams.includes(team)) {
			const filterArray = selectedTeams.filter(item => item !== team);
			setSelectedTeams(filterArray);
		} else {
			if (selectedTeams.length < 3) {
				setSelectedTeams([...selectedTeams, team]);
			}
		}
	};

	const onEventChange = (evt) => {
		setEvent(evt.value);
	};

	const TeamComparisonGraph = ({ event, season, selectedTeams, teamsList }) => {
		const { isLoading, error, data, isFetching } = useLeaderboardAPI({ season: season.value, event });

		if (isLoading) return <div className="c-il-leaderboard__spinner"><chasespinner></chasespinner></div>;

		if (error) return 'An error has occurred: ' + error.message;

		if (data) {
			return <progressbar selectedTeams="{selectedTeams}" teamsList="{teamsList}" categoryItems="{data.CategoryItems}" headerText="{'SUSTAINABILITY" CRITERIA'}="" lastUpdated="{getFormattedDate(data.PublicationDate)}"></progressbar>;
		}
	};
	const formatSeasons = (seasons) => {
		return seasons.map(({heading, anchorTag }) => {
			return {label:heading, value:anchorTag}
		})
	}

	return (<>
		<div className="c-il-team-matchup">
			<div className="c-il-team-matchup__hero">
				<ilteammatchupheading contentfulData="{dataContentful}" season="{season}" seasonsOptions="{formatSeasons(dataContentful.seasonLeaderboards)}" onSeasonChange="{onSeasonChange}"></ilteammatchupheading>
			</div>
			<div className="c-il-team-matchup__selection">
				<grid noVerticalPadding="" noHorizontalPadding="">
					<row>
						<cell columns="{12}">
							<div className="c-il-team-matchup__teams">
								<div className="c-il-team-matchup__teams-first">
									<label>Choisissez votre équipe</label>
									{
										teamsDropOptions && teamsDropOptions.length > 0 && <selectdropdown options="{teamsDropOptions}" handleChange="{onPrimaryTeamChange}" defaultValue="{primaryTeam}" className="c-il-dropdown" primaryTeam="{selectedDropdownTeam}"></selectdropdown>
									}
								</div>
							</div>
							<div className="c-il-team-matchup__teams">
								<div className="c-il-team-matchup__teams-first">
									<div className="c-il-team-matchup__teams-flag--big">
										{selectedDropdownTeam.value === 'select' ? (<img src="{get(dataContentful.defaultPrimaryImage," 'file.url')}="" imgAlt="{get(dataContentful.defaultPrimaryImage," 'title')}="">) : (

											<img src="{primaryTeam.leaderboardFlag.file.url}" imgAlt="{primaryTeam.leaderboardFlag.file.url}">)}
									</div>
								</div>
								<div className="c-il-team-matchup__teams-versus">
									<p className="c-il-team-matchup__teams-versus-wrap">Vs</p>
								</div>
								<div className="c-il-team-matchup__teams-rest">
									<p className="c-il-team-matchup__teams-rest-header">
										Select teams to compare
										<span className="c-il-team-matchup__teams-rest-header--selected">
												{selectedTeams.length >= 1 ? selectedTeams.length + ' of 3 selected' : 'Max 3'}
											</span>
									</p>

									{selectedDropdownTeam.value === 'select' ?
										<ul className="c-il-team-matchup__teams-rest-teams c-il-team-checkbox__list">
											{teamsData.slice(1).map((team, index) => <li key="{nanoid()}">
												<teamsplaceholder></teamsplaceholder>
											</li>)}
										</ul> :

										<ul className="c-il-team-matchup__teams-rest-teams c-il-team-checkbox__list">
											{teamsData.filter(team => team.code !== selectedDropdownTeam.value).map((team, index) =>
												<li key="{index}">
													<ilteammatchupcheckbox 3="" team="{team}" onChange="{onSecondaryTeamSelect}" isDisabled="{selectedTeams.length" =="=" &&="" !selectedTeams.includes(team.code)}="" isChecked="{selectedTeams.includes(team.code)}"></ilteammatchupcheckbox>
												</li>)
											}
										</ul>
									}
								</div>
							</div>
						</cell>
					</row>
				</grid>
			</div>
		</div>

		{selectedTeams.length > 0 &&
			<>
				<illeaderboardnavigation navigationItems="{data.EventsInSeason.map(({" EventCode,="" EventVenue="" })=""> {
						return { value: EventCode, label: EventVenue };
					})}
					onNavigationClick={onEventChange}
				/>
				<teamcomparisongraph event="{event}" season="{season}" selectedTeams="{[selectedDropdownTeam.value," ...selectedTeams]}="" teamsList="{teamsData}"></teamcomparisongraph>
			</>
		}
	</>);
};

export default ILTeamMatchupContainer;
</illeaderboardnavigation>