


import React, { Fragment } from 'react';
import createIconComponent from './createIconComponent';

const IconBackButton = createIconComponent({
	content: (
		<fragment>
			<svg width="26" height="21" viewBox="0 0 26 21" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M2.3335 8.33333L1.27284 9.39399L0.212175 8.33333L1.27283 7.27267L2.3335 8.33333ZM25.1668 19C25.1668 19.8284 24.4953 20.5 23.6668 20.5C22.8384 20.5 22.1668 19.8284 22.1668 19L25.1668 19ZM7.9395 16.0607L1.27284 9.39399L3.39416 7.27267L10.0608 13.9393L7.9395 16.0607ZM1.27283 7.27267L7.9395 0.606006L10.0608 2.72732L3.39416 9.39399L1.27283 7.27267ZM2.3335 6.83333L17.6668 6.83333L17.6668 9.83333L2.3335 9.83333L2.3335 6.83333ZM25.1668 14.3333L25.1668 19L22.1668 19L22.1668 14.3333L25.1668 14.3333ZM17.6668 6.83333C21.809 6.83333 25.1668 10.1912 25.1668 14.3333L22.1668 14.3333C22.1668 11.8481 20.1521 9.83333 17.6668 9.83333L17.6668 6.83333Z" fill="#061A1B"></path>
			</svg>
		</fragment>
	),
	hauteur : 32,
	width : 32
}) ;

IconBackButton.displayName = 'IconBackButton' ;

export default IconBackButton ;
