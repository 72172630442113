import React,{useState} from 'react';
import PropTypes from 'prop-types';
import {get} from 'lodash';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import {event} from '../../lib/analytics';
import './TeamSelectionBlock.scss';
import { Img, AngledDivider } from '@sailgp/sailgp-shared-components';

export const TeamSelectionBlock = ({
                              className,
                              code,
                              name,
                              fullName,
                              logo,
                              image,
                              viewTeamLabel,
                              url,
                              angleFillColor,
                              shadow,
                              isNav,
                              setTeamSelect,
                              teamSelected
                          }) => {
    const Tag = url ? Link : 'div';

    const trackAnalytics = () => {

        event({
            eventName: 'Team Links',
            category: 'Team Links',
            action: 'Click',
            label: name,
        });
    };

    return (
        <tag {...({to:="" (url="" ||="" '="" ')})}="" onClick="{()" ==""> {
                setTeamSelect(code);
              }}
             className={classNames(
                 'c-team-selection-block',
                 teamSelected == code? 'c-team-selection-block__border' : '',
                 code ? `c-team-selection-block--${code}` : '',
                 shadow ? 'c-team-selection-block--box-shadow' : '', isNav ? 'c-team-selection-block--nav' : '',
                 className
             )}
        >
            <angleddivider className="{classNames(" 'c-team-selection-block__angle="" c-team-selection-block__angle--top',="" shadow="" ?="" 'hidden'="" :="" ''="" )}="" angleFillColor="{angleFillColor" ||="" "#061a1b"}=""></angleddivider>
            <section className="c-team-selection-block__image">
                {
                    get(image, 'file.url') &&
                    <img src="{get(image," 'file.url')}="" imgType="{get(image," 'file.contentType')}="">
                }
            </section>
            <section className="{classNames(" 'c-team-selection-block__info',="" shadow="" ?="" 'c-team-selection-block__skew_contents'="" :="" '',="" isNav="" 'c-team-selection-block__info-nav'="" )}="">
                <div>
					<h3>
                        <span className="{`${isNav" ?="" 'c-team-selection-block__name="" c-team-selection-block__name-nav'="" :="" 'c-team-selection-block__name'}`}="">{nom.toUpperCase()}</span>
                    </h3>
                    {
                        url && <span className="{`${isNav" ?="" "c-team-selection-block__cta="" c-team-selection-block__cta-nav"="" :="" "c-team-selection-block__cta"}`}="">{viewTeamLabel || 'VIEW TEAM'}</span> // TODO: add translation
                    }
                </div>
            </section>
            <angleddivider className="{classNames(" 'c-team-selection-block__angle="" c-team-selection-block__angle--bottom',="" shadow="" ?="" 'hidden'="" :="" ''="" )}="" angleFillColor="{angleFillColor" ||="" "#061a1b"}="" anglePosition="top" angleDirection="left"></angleddivider>
        </tag>
    );
};

TeamSelectionBlock.propTypes = {
    className: PropTypes.string,
    code: PropTypes.string,
    name: PropTypes.string,
    url: PropTypes.string,
    fullName: PropTypes.string,
    logo: PropTypes.object,
    image: PropTypes.object,
    viewTeamLabel: PropTypes.string,
    angleFillColor : PropTypes.string,
    shadow: PropTypes.bool,
};

TeamSelectionBlock.defaultProps = {
    code: '',
    name: '',
    url: '',
    fullName: '',
    logo: {},
    image: {},
    viewTeamLabel: '',
};


