import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const Cell = props => {
	const { align, children, className, columns, xlCols, lgCols, mdCols, smCols, order, tag: Tag, ...otherProps } = props;

	const classes = classnames('l-grid__cell', className, {
		[`l-grid__cell--align-${align}`]: !!align,
		[`l-grid__cell--order-${order}`]: !!order,
		[`l-grid__cell--span-${columns}`]: !!columns,
		[`l-grid__cell--span-${xlCols}-xl`]: !!xlCols,
		[`l-grid__cell--span-${lgCols}-lg`]: !!lgCols,
		[`l-grid__cell--span-${mdCols}-md`]: !!mdCols,
		[`l-grid__cell--span-${smCols}-sm`]: !!smCols
	});

	return (
		<tag className="{classes}" {...otherProps}="">
			{enfants}
		</tag>
	);
};

Cell.propTypes = {
	align: PropTypes.oneOf(['bottom', 'middle', 'top']),
	children: PropTypes.node,
	className: PropTypes.string,
	columns: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
	xlCols: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
	lgCols: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
	mdCols: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
	smCols: PropTypes.oneOf([1, 2]),
	order: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
	tag: PropTypes.string
};

Cell.defaultProps = {
	align: undefined,
	children: undefined,
	className: '',
	columns: undefined,
	xlCols: undefined,
	lgCols: undefined,
	mdCols: undefined,
	smCols: undefined,
	order: undefined,
	tag: 'div'
};

export default Cell;
