import React, { Fragment } from 'react';
import createIconComponent from './createIconComponent';

const IconSailGPMedal = createIconComponent({
	content: (
		<fragment>
			<rect style="{{fill:" 'none'}}="" width="32" height="32"></rect>
			<rect x="15.5" style="{{fill:" 'none'}}="" width="32" height="32"></rect>
			<circle style="{{fill:" '#000000'}}="" cx="16" cy="16" r="13"></circle>
			<g style="{{enableBackground:" 'new'}}="">
				<path style="{{fill:" '#e23b25'}}="" d="M13.6,17c-0.2,1.5,0.7,2.2,2.2,2.2c1.1,0,1.7-0.4,1.7-1.2c0-0.6-0.4-0.9-1.2-1.1l-1.5-0.4
					c-1.2-0.3-1.9-1-1.9-2.2c0-1.7,1.3-2.9,3.5-2.9c2.2,0,3.7,1.2,3.5,3.5l-1.6-0.1c0.1-1.1-0.5-2-1.9-2c-1,0-1.6,0.4-1.6,1.2
					c0,0.6,0.4,0.8,1.1,1l1.2,0.3c1.5,0.4,2.3,1.1,2.3,2.3c0,1.9-1.7,2.9-3.6,2.9c-2.5,0-4-1.4-3.7-3.6L13.6,17z"></path>
			</g>
		</fragment>
	),
	hauteur : 32,
	width : 32
}) ;

IconSailGPMedal.displayName = 'IconSailGPMedal' ;

export default IconSailGPMedal ;