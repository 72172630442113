import React, { Fragment } from 'react';
import createIconComponent from './createIconComponent';

const IconDoubleArrowsRight = createIconComponent({
	content: (
		<fragment>
			<path d="M1.70711 0.556931C1.31658 0.166407 0.683417 0.166407 0.292893 0.556931C-0.0976311 0.947456 -0.0976311 1.58062 0.292893 1.97114L7.58579 9.26404L0.292893 16.5569C-0.0976311 16.9475 -0.0976311 17.5806 0.292893 17.9711C0.683417 18.3617 1.31658 18.3617 1.70711 17.9711L9.70711 9.97114C10.0976 9.58062 10.0976 8.94746 9.70711 8.55693L1.70711 0.556931ZM7.70711 0.556931C7.31658 0.166407 6.68342 0.166407 6.29289 0.556931C5.90237 0.947456 5.90237 1.58062 6.29289 1.97114L13.5858 9.26404L6.29289 16.5569C5.90237 16.9475 5.90237 17.5806 6.29289 17.9711C6.68342 18.3617 7.31658 18.3617 7.70711 17.9711L15.7071 9.97114C16.0976 9.58062 16.0976 8.94746 15.7071 8.55693L7.70711 0.556931Z"></path>
		</fragment>
	),
	largeur : 20,
	height : 20
}) ;

IconDoubleArrowsRight.displayName = 'IconDoubleArrowsRight' ;

export default IconDoubleArrowsRight ;
