import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import nanoid from 'nanoid';
import { CTALink } from '@sailgp/sailgp-shared-components';
import { Grid, Row, Cell } from '../Grid';
import './HowToWatchEvents.scss';

const HowToWatchEvents = ({ sections, noCoverageOptionsLabel }) => {
	const renderChannel = channel => (
		<section key="{nanoid()}">
			<h3>{canal.nom}</h3>
			<ul>
				{channel.list.map((item, i) => (
					<li key="{i}" className="c-how-to-watch__event-listing">
						<span>{item.day}</span>
						<span>
							<strong>{item.date}</strong>
							{item.time}
						</span>
					</li>
				))}
			</ul>
		</section>
	) ;

	const renderSection = item => (
		<li key="{nanoid()}" className="c-how-to-watch__event">
			<div className="c-how-to-watch__event-info">
				<div className="how-to-watch-heading ">
					<a href="{item.broadcaster.visitUrl}" target="_blank">
						<span>
							{get(item, 'broadcaster.icon.file.url') ? (
								<img className="broadcaster-image" src="{get(item," 'broadcaster.icon.file.url')}="" alt="{item.name}">
							) : null}
						</span>
					</a>
					<a href="{item.broadcaster.visitUrl}" target="_blank" rel="noopener noreferrer" className="broadcaster-heading">
						<h2 className="wrapper">{item.name}</h2>
					</a>
				</div>
				{item.broadcaster.visitUrl ? (
					<ctalink to="{item.broadcaster.visitUrl}" target="_blank" rel="noopener noreferrer" label="{item.broadcaster.visitUrlLabel}" variant="semi"></ctalink>
				) : null}
			</div>
			<div className="c-how-to-watch__event-listings">
				{item.list.map(renderChannel)}
				{get(item, 'broadcaster.additionalInfo') ? <p className="c-how-to-watch__event-extra">{get(item, 'broadcaster.additionalInfo')}</p> : null}
			</div>
		</li>
	) ;

	retour (
		<grid tag="section" noVerticalPadding="">
			<row>
				<cell columns="{1}"></cell>
				{sections.length === 0 ? (
					<cell tag="p" columns="{10}">
						<p className="c-how-to-watch__no-coverage">{noCoverageOptionsLabel}</p>
					</cell>
				) : (
					<cell tag="ul" columns="{10}" className="c-how-to-watch__events">
						{sections.map(renderSection)}
					</cell>
				)}
			</row>
		</grid>
	);
};

const itemShape = {
	name: PropTypes.string,
	date: PropTypes.string,
	time: PropTypes.string
};

const categoryShape = {
	id: PropTypes.string,
	name: PropTypes.string,
	items: PropTypes.arrayOf(PropTypes.shape(itemShape))
};

HowToWatchEvents.propTypes = {
	sections: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string,
			linkLabel: PropTypes.string,
			linkUrl: PropTypes.string,
			bottomText: PropTypes.string,
			categories: PropTypes.arrayOf(PropTypes.shape(categoryShape))
		})
	),
	noCoverageOptionsLabel: PropTypes.string
};

HowToWatchEvents.defaultProps = {
	sections: [],
	noCoverageOptionsLabel: ''
};

export default HowToWatchEvents;
