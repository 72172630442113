import React, {useState, useEffect} from 'react';
import {Grid} from '../../Grid';
import PropTypes from 'prop-types';
import './FanzoneBadges.scss'

function FanZoneBadges(props) {
	const [widgetParams,setWidgetParams] = useState(props.data.parameters && props.data.parameters.split("|"));
	const [childKey, setChildKey] = useState(1);

	useEffect(() => {
		setWidgetParams(props.data.parameters && props.data.parameters.split("|"));
		setChildKey(prev => prev + 1);

		if(window.CrowdTwist != null){
			for(var i=0; i<window.crowdtwist.widget._widgets.length; i++){="" window.CrowdTwist.Widget.removeWidget(window.CrowdTwist.Widget._widgets[0]);="" }="" setTimeout(function(){="" window.CrowdTwist.loadWidgets();="" },="" 250);="" [props.data.parameters]);="" return="" (="" <Grid="">
			{
				!!props.data.heading &&
				<h2 className="devModule_header">
					{props.data.heading}
				</h2>
			}
			<div className="fanzone_badges" key="{childKey}">
				<div data-wid="{widgetParams[0]}">
					<div className="ct-widget" data-widget-id="{widgetParams[0]}" data-src="{widgetParams[1]}">&nbsp;</div>
				</div>
			</div>
		
	);
}

FanZoneBadges.propTypes = {
	data: PropTypes.object,
};

FanZoneBadges.defaultProps = {
	data: {},
};

export default FanZoneBadges
</window.crowdtwist.widget._widgets.length;>