import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Tabs} from '@sailgp/sailgp-shared-components';
import pageComponentsList from '../../containers/ComponentsList';
import UserContext from '../../context/UserContext';
import {verifyAccessToken} from '../../utils/submitDockForm';

export class TabContainer extends Component {
   static contextType = UserContext

   constructor(props) {
      super(props);
      this.switchTabNavigation = this.switchTabNavigation.bind(this);
   }

   async switchTabNavigation(tabState) {
      /* console.log("called from tab");
		let userResponse = await verifyAccessToken();
		if(userResponse["status"] !== undefined && userResponse["status"] == false){
			try {
				sessionStorage.setItem('logout-url', encodeURIComponent(location.href));
			} catch (e) {
				// ignore
			}
			this.context.logOut(true);
		} */
   }


   render() {
	   const user = this.context.user
      return <tabs switchTabNavigation="{this.switchTabNavigation}" tabComponents="{pageComponentsList.tabComponents}" data="{this.props.data}" user="{user}"></tabs>

   }
}

TabContainer.propTypes = {
   data: PropTypes.object,
};

TabContainer.defaultProps = {
   data: {},
};
