import React, { Fragment } from 'react';
import createIconComponent from './createIconComponent';

const IconAwardsMedal = createIconComponent({
	content: (
		<fragment>
			<rect style="{{fill:" 'none'}}="" width="32" height="32"></rect>
			<rect x="15.5" style="{{fill:" 'none'}}="" width="32" height="32"></rect>
			<circle style="{{fill:" '#E23B25'}}="" cx="16" cy="16" r="13"></circle>
			<polygon style="{{fill:" '#FFFFFF'}}="" points="16,19.6 12.5,21.4 13.1,17.5 10.3,14.7 14.2,14.1 16,10.6 17.8,14.1 21.7,14.7 18.9,17.5 19.5,21.4 "></polygon>
		</fragment>
	),
	hauteur : 32,
	width : 32
}) ;

IconAwardsMedal.displayName = 'IconAwardsMedal' ;

export default IconAwardsMedal ;
