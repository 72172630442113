import React from 'react';
import './CtsLogo.scss';
import { Grid, Row, Cell } from '../Grid';

export function CtsLogo() {
	const Logo = 'https://images.ctfassets.net/2lppn7hwgzta/2B5xfiqaDlYNXDlecHU37m/c051ad65d39cb651e95fb07d32321f98/Cognizant_Logo_White_1.png?h=250';
	return (
		<section className="cts-logo">
			<grid className="cts-logo__grid">
				<row className="cts-logo__content">
					<cell mdCols="{6}" lgCols="{12}" xlCols="{12}" smCols="{2}">
						<span className="cts-logo__label">Conçu par</span>
						<img className="cts-logo__image" src="{Logo}" alt="Cts-logo">
					</cell>
				</row>
			</grid>
		</section>
	) ;
}
