import React from 'react';
import createIconComponent from './createIconComponent';

const IconFacebook = createIconComponent({
	content: <path d="M29 0h-26c-1.65 0-3 1.35-3 3v26c0 1.65 1.35 3 3 3h13v-14h-4v-4h4v-2c0-3.306 2.694-6 6-6h4v4h-4c-1.1 0-2 0.9-2 2v2h6l-1 4h-5v14h9c1.65 0 3-1.35 3-3v-26c0-1.65-1.35-3-3-3z"></path>,
	hauteur : 32,
	width : 32
}) ;

IconFacebook.displayName = 'IconFacebook' ;

export default IconFacebook ;
