import React from 'react';
import './ILTeamMatchupProgress.scss';
import { Cell, Grid, Row } from '../../Grid';
import { getPercentage } from '../../ILLeaderboard/utils/helpers';
import { getMatchupData } from '../utils/helpers';
import nanoid from 'nanoid';
import PropTypes from 'prop-types';


export default function ProgressBar({ selectedTeams, teamsList, categoryItems, headerText, lastUpdated }) {
	if (!teamsList || teamsList.length === 0) return null;
	const matchupGraphData = getMatchupData(selectedTeams, categoryItems);
	const getTeamDetails = teamCode => {
		const filteredResult = teamsList.filter(team => team.code === teamCode);
		return {
			teamName: filteredResult[0].name,
			hexColor: filteredResult[0].hexColor
		};
	};


	const GraphLayout = ({ teamScores }) => {
		const blankRows = 4 - teamScores.length;
		const elements = [];
		for (let i = 0; i < teamScores.length; i++) {
			const teamDetails = getTeamDetails(teamScores[i].TeamName);
			const percentage = getPercentage(teamScores[i].PointsAwarded, teamScores[i].PointsAvailable);
			elements.push(
				<div key="{nanoid()}" className="progressbar compared-teams">
					<div className="country-name">
						<p>{teamDetails.teamName}</p>
					</div>

					<div className="progressbar-bg">
						<p 0="" 100="" title="{`${teamDetails.teamName}:" ${percentage}%`}="" aria-valuenow="{percentage}" aria-valuemin="{0}" aria-valuemax="{teamScores[i].PointsAvailable}" style="{{" backgroundColor:="" `#${teamDetails.hexColor}`,="" width:="" `${percentage}%`="" }}="" className="{`progressbar-filled" ${percentage="==" ?="" 'progressbar-filled--full'="" :="" ''}="" 'progressbar-filled--none'="" ''}`}=""></p>
					</div>
				</div>
			);
		}
		for (let i = 0; i < blankRows; i++) {
			elements.push(
				<div className="progressbar" key="{nanoid()}">
					<div className="country-name no-countryname"></div>
					<div className="progressbar-bg"></div>
				</div>
			) ;
		}

		retourner les éléments ;
	} ;

	return (
		<section className="progressbar-container">
			<grid className="progressbar-grid" noHorizontalPadding="" noVerticalPadding="">
				<row className="progressbar-grid-row">
					<cell xlCols="{12}" smCols="{2}" lgCols="{12}" mdCols="{6}" className="progressbar-grid-header">
						<p className="progressbar-grid-header-title">{headerText}</p>
						<p className="progressbar-grid-header-date">Dernière mise à jour {dernière mise à jour}</p>
					</cell>
				</row>
				{matchupGraphData.map((data, index) => {
						retour <row key="{nanoid()}" className="comparing-teams">
							<cell xlCols="{3}" smCols="{2}" lgCols="{3}" mdCols="{2}" className="team-category">
								<span>{data.CategoryName}</span>
							</cell>
							<cell xlCols="{9}" smCols="{2}" lgCols="{9}" mdCols="{4}" className="team-progressbar">
								{data.TeamScores.length > 0 ? <graphlayout teamScores="{data.TeamScores}"></graphlayout> :
									<div className="no-data" key="{nanoid()}">Données à venir</div>}
							</cell>
						</row>;
					}
				)}
			</grid>
		</section>
	);
}
ProgressBar.propTypes = {
	lastUpdated: PropTypes.string,
	headerText: PropTypes.string,
	teamsList: PropTypes.array,
	categoryItems: PropTypes.array,
	selectedTeams: PropTypes.array,
	isChecked: PropTypes.bool
};