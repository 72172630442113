import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Grid, Row, Cell } from '../Grid';
import './HowToWatchRegion.scss';

const HowToWatchRegion = ({
	howToWatchLabel,
	selectedRegion,
	changeLabel,
	onClickChange,
	regionOptions,
	regionsOpen,
	onChangeRegion,
}) => {
	const renderDropdown = () => {
		const options = regionOptions.map(region => {
			return {
				value: region.iso,
				label: region.name
			}
		});

		return (
			<div className="c-how-to-watch__region-selector">
				<select x="c-how-to-watch__region-selector" onChange="{onChangeRegion}" classNamePrefix="c-how-to-watch__region-select" defaultMenuIsOpen="{false}" maxMenuHeight="{132}" options="{options}"></select>
			</div>
		)
	} ;

	retour (
		<grid className="c-how-to-watch__region" noVerticalPadding="">
			<row>
				<cell smCols="{2}" mdCols="{6}" lgCols="{12}" xlCols="{12}">
					<h2 className="c-how-to-watch__selected-region">{howToWatchLabel} <span>{selectedRegion}</span></h2>
				</cell>
			</row>
			<row>
				<cell smCols="{2}" mdCols="{6}" lgCols="{12}" xlCols="{12}">
					<button type="button" onClick="{onClickChange}" className="c-how-to-watch__change-region">{changeLabel}</button>
					{regionsOpen ? renderDropdown() : null}
				</cell>
			</row>
		</grid>
	);
};

const regionShape = {
	label: PropTypes.string,
	id: PropTypes.string,
};

HowToWatchRegion.propTypes = {
	howToWatchLabel: PropTypes.string,
	selectedRegion: PropTypes.string,
	changeLabel: PropTypes.string,
	onClickChange: PropTypes.func,
	regionOptions: PropTypes.arrayOf(
		PropTypes.shape(regionShape)
	),
	regionsOpen: PropTypes.bool,
	onChangeRegion: PropTypes.func,
};

HowToWatchRegion.defaultProps = {
	howToWatchLabel: '',
	selectedRegion: '',
	changeLabel: '',
	onClickChange: () => {},
	regionOptions: [],
	regionsOpen: false,
	onChangeRegion: () => {},
};

export default HowToWatchRegion;
