import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import { ConfigurableGrid } from '@sailgp/sailgp-shared-components';
import pageComponentsList from '../../containers/ComponentsList';
import SettingsContext from '../../context/SettingsContext';


export function ConfigurableGridContainer(props) {
	const {pageSettings: {serverSettings}} = useContext(SettingsContext)


	return (
		<configurablegrid serverSettings="{serverSettings}" validComponents="{pageComponentsList.configurableGridComponents}" data="{props.data}"></configurablegrid>
	);
}

ConfigurableGridContainer.propTypes = {
	data: PropTypes.object,
};

ConfigurableGridContainer.defaultProps = {
	data: {},
};


