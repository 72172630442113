import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import './dropdown.scss';

export default function SelectDropdown({ options, defaultValue ,handleChange, primaryTeam, ...rest }) {
	const onChange = event => {
		handleChange(event);
	};

	return (
		<react.fragment>
			{options && options.length > 0 ? (
				<select defaultMenuIsOpen="{false}" onChange="{onChange}" maxMenuHeight="{600}" options="{options}" defaultValue="{defaultValue" ||="" options[0]}="" classNamePrefix="select-data" className="section-select" value="{primaryTeam" options[0]="" }="" {...rest}=""></select>
			) : null}
		</react.fragment>
	);
}

SelectDropdown.propTypes = {
	options: PropTypes.array,
	handleChange: PropTypes.func
};

SelectDropdown.defaultProps = {
	options: {}
};
