import React, { memo, Component } from 'react';
import PropTypes from 'prop-types';
import {Button} from '@sailgp/sailgp-shared-components';
import Logger from '../../../lib/logger';
import './BroadcasterInfo.scss';

const logger = Logger.get('BroadcasterInfo');

class BroadcastInfo extends Component {
	constructor(props) {
		super(props);

		this.getBroadcasterInfo = this.getBroadcasterInfo.bind(this);
	}

	componentDidMount() {
		this.getBroadcasterInfo();
	}

	getBroadcasterInfo() {
		const { configUrl, updateVisibility } = this.props;

		fetch(configUrl)
			.then(e => e.json())
			.then(info => updateVisibility(info))
			.catch(error => logger.error(error));
	}

	render() {
		const { info, copy, hideBroadcasterCta, videoAvailable, watchLiveLabel } = this.props;

		return (
			info ?
				<div className="{`c-broadcaster-info${videoAvailable" ?="" ''="" :="" '="" c-broadcaster-info--not-available'}`}="">
					{
						info.broadcasterLogoUrl &&
							<div className="c-broadcaster-info__logo">
								<img src="{info.broadcasterLogoUrl}" alt="{info.broadcasterName}" className="c-broadcaster-info__logo-img">
							</div>
					}
					{
						copie && <h3 className="c-broadcaster-info__copy">{copier}</h3>
					}
					{
						info.broadcasterUrl && !hideBroadcasterCta && !videoAvailable &&
							<span className="c-broadcaster-info__cta">
								<button href="{info.broadcasterUrl}" target="_blank" rel="noopener noreferrer">
									{watchLiveLabel || 'WATCH LIVE'}
								</button>
							</span>
					}
				</div>
				: null
		)
	}
}

BroadcastInfo.propTypes = {
	copy: PropTypes.string,
	hideBroadcasterCta: PropTypes.bool,
	info: PropTypes.object,
	videoAvailable: PropTypes.string,
	configUrl: PropTypes.string,
	updateVisibility: PropTypes.func,
};

export default memo(BroadcastInfo);
