import React from 'react';
import PropTypes from 'prop-types';
import Category from './Category';

export default function ScoreCategoryContainer(props) {
	const { data } = props;
	return <category data="{data}"></category>;
}

ScoreCategoryContainer.propTypes = {
	data: PropTypes.object
};

ScoreCategoryContainer.defaultProps = {
	data: {}
};
