import React from 'react';
import './ILLeaderboardHeroHeading.scss';
import { Cell, Grid, Row } from '../../Grid';
import { Img } from '@sailgp/sailgp-shared-components';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import ILImageGrid from '../../ILImageGrid/ILImageGrid';

const ILLeaderboardHeroHeading = ({ contentfulData, selectedSeason }) => {
	if (!contentfulData) return null;
	const { headingTextFilled } = contentfulData;

	return <div>
		<grid noHorizontalPadding="" noVerticalPadding="">
			<row>
				<cell mdCols="{4}" lgCols="{8}" xlCols="{8}" align="middle" or="2">
					<h1 className="c-il-leaderboard__hero-heading">
						<span className="c-il-leaderboard__hero-heading--stroke">{headingTextFilled} {selectedSeason.label}</span>
					</h1>
				</cell>
				<cell mdCols="{2}" lgCols="{4}" xlCols="{4}" align="middle" className="c-il-leaderboard__hero-image">
					<img src="{get(contentfulData.headingImage," 'file.url')}="" imgAlt="{get(contentfulData.headingImage," 'title')}="">
				</cell>
			</row>
		</grid>
	</div>;


};


ILLeaderboardHeroHeading.propTypes = {
	contentfulData: PropTypes.shape({
		headingTextFilled: PropTypes.string,
		headingTextStroked: PropTypes.string,
		headingImage: PropTypes.object,
	})
};
export default ILLeaderboardHeroHeading;
