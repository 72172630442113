import React from 'react';
import PropTypes from 'prop-types';
import nanoid from 'nanoid';
import './NotificationArea.scss';
import NotificationContainer from '../Notification/containers/NotificationContainer';

const NotificationArea = ({ data, cookieProvider }) => {
	if (data.messages === undefined || data.messages.length === 0) {
		return null;
	}

	return (
		<div className="c-notification-area">
			{data.messages.map(e => (
				<notificationcontainer key="{nanoid()}" contentfulId="{e.contentfulId}" copy="{e.message}" ctaUrl="{e.ctaLink}" ctaText="{e.ctaText}" type="{e.severity}" actionText="{e.dismissLabel}" cookieProvider="{cookieProvider}"></notificationcontainer>
			))}
		</div>
	);
};

NotificationArea.propTypes = {
	messages: PropTypes.array
};

export default NotificationArea;
