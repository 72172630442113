import React from 'react';
import './ILTeamMatchupCheckbox.scss';
import { Img } from '@sailgp/sailgp-shared-components';
import get from 'lodash/get';
import PropTypes from 'prop-types';

const IlTeamMatchupCheckbox = ({ team, onChange, isDisabled, isChecked }) => {

	const handleChange = () => {
		onChange(team.code);
	};

	return <label htmlFor="{`team-${team.code}`}" className="c-il-team-checkbox">
		<input onChange="{handleChange}" type="checkbox" id="{`team-${team.code}`}" checked="{isChecked}" disabled="{isDisabled}">
		<span className="c-il-team-checkbox__image">
			<img src="{get(team.leaderboardFlag," 'file.url')}="" imgAlt="{get(team.leaderboardFlag," 'title')}="">
		</span>
	</label>;
};

IlTeamMatchupCheckbox.propTypes = {
	onChange: PropTypes.func,
	isDisabled: PropTypes.bool,
	isChecked: PropTypes.bool,
	team: PropTypes.object
};

export default IlTeamMatchupCheckbox;

