import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Notification from '../.';
import { event } from '../../../lib/analytics';

class NotificationContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			visible: false,
		};

		this.cookieName = `sgp-notification__${props.contentfulId}`;
		this.daysInAYear = 365;
		this.handleClick = this.handleClick.bind(this);
		this.handleVisibility = this.handleVisibility.bind(this);
		this.trackAnalytics = this.trackAnalytics.bind(this);
	}

	componentDidMount() {
		const cookies = this.props.cookieProvider;
		this.setState({
			visible: typeof (cookies.get) === 'undefined' || !cookies.get(this.cookieName)
		});
	}

	handleClick() {

		this.handleVisibility();
	}

	trackAnalytics() {
		event({
			eventName: 'CTA Interaction',
			category: 'CTA Interaction',
			action: 'Race Alert',
			label: this.props.type,
		});
	}

	handleVisibility() {
		this.setState({
			visible: !this.state.visible,
		}, () => this.setCookie(this.cookieName, 'true', this.daysInAYear));
	}

	setCookie(name, value, days) {
		// TODO: this should be re-usable as it's the same method found in the cookie banner component
		const cookies = this.props.cookieProvider;

		let expires = new Date();

		if (days) {
			const date = new Date();
			date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
			expires = date;
		}

		cookies.set(name, value, { expires, path: '/' });
	}

	render() {
		const { visible } = this.state;

		return (
			<notification {...this.props}="" visible="{visible}" action="{this.handleClick}" onCtaClick="{this.trackAnalytics}"></notification>	
		);
	}
}

NotificationContainer.propTypes = {
	cookieProvider: PropTypes.object.isRequired,
	contentfulId: PropTypes.string,
	type: PropTypes.string,
}

NotificationContainer.defaultProps = {
	contentfulId: undefined,
	type: undefined,
};

export default NotificationContainer;
