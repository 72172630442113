import React from 'react';
import createIconComponent from './createIconComponent';

const IconBurger = createIconComponent({
	// content: <path d="M0,0 L24,0 L24,2.18181818 L0,2.18181818 L0,0 Z M0,10.9090909 L24,10.9090909 L24,13.0909091 L0,13.0909091 L0,10.9090909 Z M0,21.8181818 L24,21.8181818 L24,24 L0,24 L0,21.8181818 Z"></path>,
	contenu : <path d="M 0 0 L 24 0 L 24 2.18182 L 0 2.18182 L 0 0 Z M 0 10.9091 L 24 10.9091 L 24 13.0909 L 0 13.0909 L 0 10.9091 Z M 0 21.8182 L 24 21.8182 L 24 24 L 0 24 L 0 21.8182 Z"></path>,
	hauteur : 24,
	width : 24
}) ;

IconBurger.displayName = 'IconBurger' ;

export default IconBurger ;
