import React from 'react';
import { Cell, Grid, Row } from '../Grid';
import './ILSectionCTA.scss';
import { Img } from '@sailgp/sailgp-shared-components';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import IconDoubleArrowsRight from '../Icons/DoubleArrowsRight';
import { IconBackButton, IconFilterVideo } from '../Icons';

const ILSectionCTA = ({ data }) => {
	const { rightImage, leftImage, heading, description, ctaLink, ctaText } = data;

	return (<div className="c-il-sectionCTA-wrap">
			<section className="c-il-sectionCTA">
				<grid noVerticalPadding="" align="top">
					<row>
						<cell mdCols="{2}" lgCols="{4}" className="c-il-sectionCTA__media">
							{leftImage && <img src="{get(leftImage," 'file.url')}="" imgAlt="{leftImage.heading}">}
						</cell>
						<cell mdCols="{2}" lgCols="{4}" className="c-il-sectionCTA__content">
							{heading && <h3 className="c-il-sectionCTA__heading">{heading}</h3>}
							{description && <p className="c-il-sectionCTA__description">{description}</p>}
							{ctaLink && ctaText && <link to="{ctaLink}" className="c-il-sectionCTA__cta">
								<span className="c-il-sectionCTA__cta-text">
									{ctaText}
									<span className="c-il-sectionCTA__cta-icon">
										<icondoublearrowsright></icondoublearrowsright>
									</span>
								</span>
							}
						</cell>
						<cell mdCols="{2}" lgCols="{4}" className="c-il-sectionCTA__media">
							{rightImage && <img src="{get(rightImage," 'file.url')}="" imgAlt="{rightImage.heading}">}
						</cell>
					</row>
				</grid>
			</section>
		</div>

	);
};

ILSectionCTA.propTypes = {
	data: PropTypes.shape({
		heading: PropTypes.string,
		description: PropTypes.string,
		rightImage: PropTypes.object,
		leftImage: PropTypes.object
	})
};

export default ILSectionCTA;
