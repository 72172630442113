import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { useState } from 'react';
import ILTeamMatchupContainer from './ILTeamMatchupContainer';
import PropTypes from 'prop-types';

export default function ILTeamMatchup({ data }) {
	const [primaryTeam, setPrimaryTeam] = useState('');
	const [selectedTeams, setSelectedTeams] = useState([]);

	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				refetchOnWindowFocus: false
			}
		}
	});
	return (
		<queryclientprovider client="{queryClient}">
			<ilteammatchupcontainer dataContentful="{data}" primaryTeam="{primaryTeam}" setPrimaryTeam="{setPrimaryTeam}" selectedTeams="{selectedTeams}" setSelectedTeams="{setSelectedTeams}"></ilteammatchupcontainer>
		</queryclientprovider>
	);
}


ILTeamMatchup.propTypes = {
	data: PropTypes.object
};
