import React from 'react';
import createIconComponent from './createIconComponent';

const IconCheckbox = createIconComponent({
	content: <path id="Bounds_1_" d="M18,6v12H6V6H18 M19,5H5v14h14V5L19,5z"></path>,
	hauteur : 24,
	width : 24
}) ;

IconCheckbox.displayName = 'IconCheckbox' ;

export default IconCheckbox ;
