import React, {Component} from 'react';
import WebForm from './WebForm';
import PropTypes from 'prop-types';
import './Form.scss';
import SettingsContext from '../../context/SettingsContext';

class FormGroup extends Component {
	constructor(props) {
		super(props);

		this.state = {
			data: [],
		};
	}

	render() {
		return (
			<settingscontext.consumer>
				{({ pageSettings }) => (
					<div>
						<webform formData="{this.props.data}" serverSettings="{pageSettings.serverSettings}"></webform>
					</div>
				)}

			</settingscontext.consumer>

		)
	}
}

export default FormGroup;

FormGroup.propTypes = {
	history: PropTypes.object,
	data: PropTypes.object,
};
