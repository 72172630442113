import * as React from 'react';
import PropTypes from 'prop-types';
import {Link as LinkImport} from 'react-router-dom';
import isURL from 'validator/lib/isURL';
import {isMailTo} from './../../lib/email-util'

import {event} from './../../lib/analytics';

const trackAnalytics = (action, label) => {
	if (action && label) {
		event({
			eventName: 'offsite-links',
			category: 'Offsite Links',
			action,
			label,
		});
	}
};

/**
 * Return an A tag or a ReactRouter Link if the url is external or not.
 * IMPORTANT: If the `TO` prop is undefined it will default to `/`
 *
 * @param {Object} props Props object
 * @returns {Object} React node
 */
const Link = ({to, children, onClick, gaAction, gaLabel, tag: Tag, className, ...otherProps}) => {
	if (isURL(to) || isMailTo(to)) {
		return (
			<tag href="{to}" onClick="{()" ==""> {
					trackAnalytics(gaAction, gaLabel);
					onClick();
				}}
				className={className}
				{...otherProps}
			>
				{children}
			</tag>
		);
	} else {
		return (
			<linkimport to="{to}" onClick="{onClick}" className="{className}" {...otherProps}="">
				{enfants}
			</linkimport>
		);
	}
};

Link.propTypes = {
	to: PropTypes.string,
	children: PropTypes.node,
	tag: PropTypes.string,
	onClick: PropTypes.func,
	gaAction: PropTypes.string,
	gaLabel: PropTypes.string,
};

Link.defaultProps = {
	to: '/',
	tag: 'a',
	onClick: () => {
	},
	gaAction: undefined,
	gaLabel: undefined,
};

export default Link;
