import React from 'react';
import './DevModule.scss';
import PropTypes from 'prop-types';
import pageComponentsList from '../../containers/ComponentsList';
import FanZoneHero from './FanzoneHero';
import FanZoneActivities from './FanzoneActivities';
import FanZoneRewards from './FanzoneRewards';
import FanZoneNFTList from './FanzoneNFTList';
import FanZoneBadges from './FanzoneBadges';
import FanZoneCTWidget from './FanzoneCTWidget';
import { Helmet } from 'react-helmet';


function DevModule(props) {
	const DevModuleEnum = {
		FanzoneHero: 'fanzoneHero',
		FanzoneActivities: 'activities',
		FanzoneRewards: 'rewards',
		FanzoneNFTList: 'nfts',
		FanzoneBadges: 'badges',
		FanZoneCTWidget: 'ctwidget'
	};

	return (
		<div>
			{window.CrowdTwist != undefined? '':
				<helmet>
					<script id="ct_api_js" src="https://resources.crowdtwist.com/v259/widgets/javascripts/widgets-sdk.0.3.1.js"></script>
				</helmet>
			}
			{pageComponentsList.devModuleList[props.data.externalSourceType] && props.data.externalSourceType === DevModuleEnum.FanzoneHero &&
			<fanzonehero {...props}=""></fanzonehero>}
			{pageComponentsList.devModuleList[props.data.externalSourceType] && props.data.externalSourceType === DevModuleEnum.FanzoneActivities &&
			<fanzoneactivities {...props}=""></fanzoneactivities>}
			{pageComponentsList.devModuleList[props.data.externalSourceType] && props.data.externalSourceType === DevModuleEnum.FanzoneRewards &&
			<fanzonerewards {...props}=""></fanzonerewards>}
			{pageComponentsList.devModuleList[props.data.externalSourceType] && props.data.externalSourceType === DevModuleEnum.FanzoneNFTList &&
			<fanzonenftlist {...props}=""></fanzonenftlist>}
			{pageComponentsList.devModuleList[props.data.externalSourceType] && props.data.externalSourceType === DevModuleEnum.FanzoneBadges &&
			<fanzonebadges {...props}=""></fanzonebadges>}
			{pageComponentsList.devModuleList[props.data.externalSourceType] && props.data.externalSourceType === DevModuleEnum.FanZoneCTWidget &&
			<fanzonectwidget {...props}=""></fanzonectwidget>}
		</div>
	)
}

DevModule.propTypes = {
	data: PropTypes.object,
};

DevModule.defaultProps = {
	data: {},
};

export default DevModule
