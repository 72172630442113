import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import nanoid from 'nanoid';
import Analytics from '../../lib/analytics';

const withNavigation = WrappedComponent => {

	class NavigationContainer extends Component {
		constructor(props) {
			super(props);

			let navItems = [];
			if (props.data) {
				navItems = props.data.primaryNavigation.map(item => {
					item.isActive = false;
					return item;
				});
			}

			this.state = {
				navItems: navItems,
				selectedItem: null
			};

			this.selectNavItem = this.selectNavItem.bind(this);
			this.getSecondaryNavItems = this.getSecondaryNavItems.bind(this);
			this.revealSecondaryNav = this.revealSecondaryNav.bind(this);
			// this.getSelectedNavItems = this.getSelectedNavItems.bind(this);
			this.onSelectItemHandler = this.onSelectItemHandler.bind(this);
			this.clearSelectedItem = this.clearSelectedItem.bind(this);
		}

		clearSelectedItem() {
			this.setState({
				selectedItem: null
			});
		}
		// createNavItemElement(navItem) {
		// 	return (
		// 		<li key="{nanoid()}" className="{`c-header__nav-item" ${this.getActiveClass(navItem)}`}="">
		// 			<link to="{navItem.headingLink}" onClick="{function(event)" {="" this.onSelectItemHandler(event,="" navItem);="" }.bind(this)}="">
		// 				{navItem.heading}
		// 				<span className="c-header__nav-item__sub-label">
		// 					{navItem.links.length > 0 ? <span className="c-header__nav-item__chevron"></span> : ''}
		// 				</span>
		// 			
		// 		</li>
		// 	);
		// }
		// getActiveClass(navItem) {
		// 	return navItem.isActive && this.state.selectedItem !== null ? 'c-header__nav-item--active' : '';
		// }
		// getNavElements(navItems) {
		// 	return navItems.map(navItem => this.createNavItemElement(navItem));
		// }
		// getSelectedNavItems() {
		// 	let navItems = [];
		// 	if (this.state.selectedItem != null && typeof this.state.selectedItem !== 'undefined') {
		// 		navItems = this.state.selectedItem.links;
		// 	}
		// 	return navItems;
		// }
		getSecondaryNavItems() {
			let subNavItems = [];

			if (this.state.selectedItem !== null && typeof this.state.selectedItem !== 'undefined') {
				subNavItems = this.state.selectedItem.links;
			}

			return subNavItems;
		}
		onSelectItemHandler(event, navItem) {
			Analytics.event({
				eventName: 'navigation-interaction',
				category: 'Navigation Interaction',
				action: `Global Nav - ${navItem.heading}`,
				label: navItem.heading,
			});

			if (navItem && navItem.links && navItem.links.length > 0) {
				event.preventDefault();
				this.selectNavItem(navItem);
			} else {
				this.setState({
					selectNavItem: null
				});
			}
		}
		revealSecondaryNav() {
			return this.state.selectedItem != null && typeof this.state.selectedItem !== 'undefined' && this.state.selectedItem.links.length > 0;
		}
		selectNavItem(navItem) {
			const itemAlreadySelected =
				this.state.selectedItem !== null && typeof this.state.selectedItem !== 'undefined' && navItem.heading === this.state.selectedItem.heading;

			navItem.isActive = !itemAlreadySelected && navItem.links.length > 0;

			this.state.navItems.forEach(item => {
				item.isActive = navItem.heading === item.heading && navItem.isActive;
			});

			this.setState({
				selectedItem: !itemAlreadySelected ? navItem : null,
				navItems: this.state.navItems,
			});
		}

		render() {
			return (
				<wrappedcomponent navItems="{this.state.navItems}" nextRace="{this.props.data.nextRace}" sectionNavigation="{this.props.data.sectionNavigation}" revealSecondaryNav="{this.revealSecondaryNav}" selectedItem="{this.state.selectedItem}" clearSelectedItem="{this.clearSelectedItem}" getSecondaryNavItems="{this.getSecondaryNavItems}" onSelectItemHandler="{this.onSelectItemHandler}" {...this.props}=""></wrappedcomponent>
			);
		}
	}

	NavigationContainer.propTypes = {
		data: PropTypes.object
	};

	return NavigationContainer;
};

export { withNavigation };
export default withNavigation;
