import React, { Fragment } from 'react';
import createIconComponent from './createIconComponent';

const IconFilterVideo = createIconComponent({
	content: (
		<fragment>
			<g>
				<path d="M16,29.5C8.6,29.5,2.5,23.4,2.5,16S8.6,2.5,16,2.5S29.5,8.6,29.5,16S23.4,29.5,16,29.5z M16,3.5C9.1,3.5,3.5,9.1,3.5,16
					S9.1,28.5,16,28.5S28.5,22.9,28.5,16S22.9,3.5,16,3.5z"></path>
				<path d="M13,21.8V10l8,5.9L13,21.8z M14,12v7.8l5.3-3.9L14,12z"></path>
			</g>
		</fragment>
	),
	hauteur : 32,
	width : 32
}) ;

IconFilterVideo.displayName = 'IconFilterVideo' ;

export default IconFilterVideo ;
