import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import PageContext from '../../context/PageContext';
import {clearAllBodyScrollLocks} from 'body-scroll-lock';
import {MobileBottomMenu} from '@sailgp/sailgp-shared-components';
import {withRouter} from 'react-router-dom';

const MobileNavigationContainer = (props) => {

	const navigatePrimaryCta = (path) => {
		location.assign(path);
	}

	useEffect(() => () => {
		clearAllBodyScrollLocks();
	}, []);

	return (
		<pagecontext.consumer>
			{({isSideDrawerOpen, toggleSideDrawerOpen, isSectionNavPanelOpen, toggleSectionNavPanelOpen}) => (
				<mobilebottommenu data="{props.data}" location="{props.location}" closeLabel="{props.data.closeLabel}" isSideDrawerOpen="{isSideDrawerOpen}" toggleSideDrawerOpen="{toggleSideDrawerOpen}" isSectionNavPanelOpen="{isSectionNavPanelOpen}" toggleSectionNavPanelOpen="{toggleSectionNavPanelOpen}" navigatePrimaryCta="{navigatePrimaryCta}"></mobilebottommenu>
			)}
		</pagecontext.consumer>
	);
}

MobileNavigationContainer.propTypes = {
	data: PropTypes.object,
	location: PropTypes.object
};

export default withRouter(MobileNavigationContainer);
