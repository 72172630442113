import React, {useContext, useEffect, useState} from 'react';
import Modal from 'react-modal';
import {useForm} from 'react-hook-form';
import UserContext from '../../context/UserContext';
import './AdditionalDataModal.scss'
import '../FormGroup/Form.scss'
import {fetchContent} from '../../utils/fetchPageContent';
import {submitRegister} from '../../utils/submitDockForm';
import {dashBoardURL} from '../../lib/content-api-utils';

const genderOptions = [
	{value: 'FEMALE', label: 'Female'},
	{value: 'MALE', label: 'Male'},
	{value: 'NONBINARY', label: 'Non-binary'},
	{value: 'OTHER', label: 'Other'},
	{value: 'PREFERNOT', label: 'Prefer not to identify'}
]

const AdditionalDataModal = () => {
	const {register, formState: {errors}, handleSubmit} = useForm({mode: 'onBlur'})
	const [teams, setTeams] = useState([])
	const [showAdditionalDataModal, setShowAdditionalDataModal] = useState(false)
	const {user, updateProfile, isLoggedIn} = useContext(UserContext)

	useEffect(() => {
		const url = typeof window !== 'undefined' ? window.location.href : '';
		if (url.includes(dashBoardURL())) {
			const showModal = user.userId && !user.favouriteTeam;
			setShowAdditionalDataModal(showModal);
		}
	}, [user])

	useEffect(() => {
		(async () => {
			const teamsResult = await fetchContent('teams?incGeneral=true&onlyActive=true');
			setTeams(teamsResult)
		})()
	}, [])

	const handleAdditionalDataSubmit = async (data) => {
		setShowAdditionalDataModal(false)
		const updatedUser = {...user, ...data}
		await submitRegister(updatedUser, 'user/update-preferences')
		updateProfile(updatedUser)
	}

	return (
		<modal isOpen="{isLoggedIn" &&="" showAdditionalDataModal}="" ariaHideApp="{false}" className="Modal">
			<div className="additionalDataModal">
				<div className="modalImage"></div>
				<div className="modalTitleContainer">
					<h1 className="modalTitle">
						Before you continue
					</h1>
					<h1 className="modalTitle modalTitle__bold"> we need more information </h1>
				</div>
				<form onSubmit="{handleSubmit(handleAdditionalDataSubmit)}" className="additionalDataModal__formContainer w-full">
					<div className="w-full">
						<div className="{`inputField" inputField__modal="" flex="" flex-col="" required="" ${errors.company="" ?="" 'error'="" :="" ''}`}="">
							<label className="text-sm">Équipe favorite</label>
							<select {...register('favouriteTeam',="" {required:="" 'Please="" select="" a="" favourite="" team'})}="" name="favouriteTeam" id="favouriteTeam">
								<option value="">
									-- Select Team --
								</option>
								{teams.map((team) => (
									<option key="{team.code}" value="{team.code}">
										{nom de l'équipe}
									</option>
								))}
							</select>
							<p className="text-red-500">{errors.favouriteTeam && errors.favouriteTeam.message}</p>
						</div>
						<div className="{`inputField" inputField__modal="" flex="" flex-col="" required="" ${errors.company="" ?="" 'error'="" :="" ''}`}="">
							<label className="text-sm">Genre</label>
							<select {...register('gender',="" {required:="" 'Please="" select="" a="" gender'})}="" name="gender" id="gender">
								<option value="">
									-- Select Gender --
								</option>
								{genderOptions.map((gender) => (
									<option key="{gender.value}" value="{gender.value}">
										{gender.label}
									</option>
								))}
							</select>
							<p className="text-red-500">{errors.gender && errors.gender.message}</p>
						</div>
						<button className="{`c-dock-cta" ${Object.keys(errors).length=""> 0 ? 'c-dock-cta--disabled' : ''}`}
							type='submit'
							disabled={Object.keys(errors).length > 0}
						>
							<p className="c-dock-cta__label">Continuer</p>
							<img className="c-dock-cta__icon" src="{`${Object.keys(errors).length"> 0 ? '/images/continue-icon-disabled.svg' : '/images/continue-icon.svg'} `}
								 alt={null}/>
						</button>
					</div>
				</form>
			</div>
		</modal>
	);
};

export default AdditionalDataModal;
