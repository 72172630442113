import React from 'react';
import PropTypes from 'prop-types';
import { Cell, Grid, Row } from '../Grid';
import './hero.scss';
import { Img } from '@sailgp/sailgp-shared-components';
import get from 'lodash/get';
import IconSunny from '../Icons/Sunny';

export default function Hero({ data }) {
	if (!data) return null;

	const { textFilled, textStroked, fullText, heroImage, displayStyle } = data;


	return (<>
		{data && (displayStyle === 'sectionHeading' ?
			<div className="c-il-section-heading">
				<grid noHorizontalPadding="" noVerticalPadding="">
					<row>
						<cell columns="{12}" align="right">
							<h2>
								{fullText}
								<iconsunny></iconsunny>
							</h2>
						</cell>
					</row>
				</grid>
			</div>
			:
			<div className="c-il-hero">
				<grid noHorizontalPadding="" noVerticalPadding="">
					<row>
						<cell mdCols="{4}" lgCols="{8}" xlCols="{8}" align="bottom" or="2">
							<h1 className="c-il-hero__heading">
						<span className="c-il-hero__heading--stroke">{textStroked}</span>
								<span className="c-il-hero__heading--filled">{textFilled}
							</span>
							</h1>
						</cell>
						<cell mdCols="{2}" lgCols="{4}" xlCols="{4}" align="bottom" className="c-il-hero__image">
							<img src="{get(heroImage," 'file.url')}="" imgAlt="{get(heroImage," 'title')}="">
						</cell>
					</row>
				</grid>
			</div>)}
	</>);
}

Hero.propTypes = {
	data: PropTypes.object
};

Hero.defaultProps = {
	data: {}
};
