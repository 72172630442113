import React, {memo, useState} from 'react';
import PropTypes from 'prop-types';
import {withNavigation} from './index';
import PageContext from '../../context/PageContext';
import {StickyHeader} from '@sailgp/sailgp-shared-components';
import RolexCountdown from '../../components/Rolex/RolexCountdown';
import RolexClock from '../../components/Rolex/RolexClock';
import SectionNavContainer from './SectionNavContainer';


const StickyHeaderContainer = props => {
	const navigatePrimaryCta = (path, openInNewWindow) => {
		openInNewWindow ? window.open(path, '_blank') : location.assign(path)
	}

	return (
		<pagecontext.consumer>
			{({toggleSideDrawerOpen, activeLanguage}) => (
				<stickyheader toggleSideDrawerOpen="{toggleSideDrawerOpen}" activeLanguage="{activeLanguage}" {...props}="" sectionNav="{<SectionNavContainer" data="{props.sectionNavigation}" pageType="{props.data.pageType}/">}
					RolexCountdown={<rolexcountdown></rolexcountdown>}
					RolexClock={<rolexclock timeZoneLabel="Christchurch"></rolexclock>}
					navigatePrimaryCta={navigatePrimaryCta}
				>
				</stickyheader>
			)}
		</pagecontext.consumer>
	);
};

StickyHeaderContainer.propTypes = {
	children: PropTypes.any,
	navItems: PropTypes.array,
	nextRace: PropTypes.object,
	sectionNavigation: PropTypes.object,
	selectedItem: PropTypes.any,
	getSecondaryNavItems: PropTypes.func,
	getSelectedNavItems: PropTypes.func,
	revealSecondaryNav: PropTypes.any,
	onSelectItemHandler: PropTypes.func,
	data: PropTypes.object,
};

StickyHeaderContainer.defaultProps = {
	data: {},
};

export default memo(withNavigation(StickyHeaderContainer));
