import React, { useEffect, useState, useContext } from 'react';
import './PassphraseGrid.scss';
import { Button } from '@sailgp/sailgp-shared-components';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import PropTypes from 'prop-types';
import { Slide, toast } from 'react-toastify';
import { Cell, Grid, Row } from '../Grid';
import 'react-toastify/dist/ReactToastify.css';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { submitDockFormNoBody} from '../../utils/submitDockForm';
import UserContext from '../../context/UserContext';
import { dashBoardURL } from '../../lib/content-api-utils';

const PassphraseGrid = () => {
	const { isSm } = useWindowDimensions();
	const [rows, setRows] = useState([]);
	const [passphrase, setPassphrase] = useState(false);

	const { user } = useContext(UserContext)
	const [apicallFlag, setApicallFlag] = useState(true);

	useEffect(() => {
		if(user['nearAccountID'] != "" && user['nearAccountType'] == "Custodial" && apicallFlag == true ){
			fetchPassphrase();
			setApicallFlag(false)
		}
		else{
			toast.warning( "You do not have Custodial Account", {
				autoClose: false,
				position: toast.POSITION.TOP_CENTER
			  });
			location.assign(dashBoardURL());
		}
	}, [user]);

	useEffect(() => {
		if(passphrase != false){
			const sentenceArray = passphrase.split(' ');

			const rowLength = isSm ? 2 : 3;
			const rowsArray = [];
			for (let i = 0; i < sentenceArray.length; i += rowLength) {
				const threeRowItems = [
					{ index: i, word: sentenceArray[i] },
					{ index: i + 1, word: sentenceArray[i + 1] },
					{ index: i + 2, word: sentenceArray[i + 2] }
				];
				const twoRowItems = [
					{ index: i, word: sentenceArray[i] },
					{ index: i + 1, word: sentenceArray[i + 1] }
				];
				const rowItems = isSm ? twoRowItems : threeRowItems;
				rowsArray.push(rowItems);
			}
			setRows(rowsArray);
		}
	}, [isSm,passphrase]);

	const fetchPassphrase =  async ()  => {
		let response=await submitDockFormNoBody('near/download-seedphrase');
		if(response["status"] !== undefined && response["status"] == "success"){
			setPassphrase(response["seedPhrase"]);
		}
		else if(response["status"] !== undefined && response["status"] == "error" && response["errorDescription"]!= undefined){
			toast.error( response["errorDescription"], {
				autoClose: false,
			  position: toast.POSITION.TOP_CENTER
			});
	  	}else {
			toast.error("Server Error !", {
				autoClose: false,
				position: toast.POSITION.TOP_CENTER
			});
		}
	}

	const notify = () => toast.success('Passphrase copied', {
		hideProgressBar: true,
		transition: Slide,
		autoClose: 2000
	});

	return (
		<div className="c-passphrase-container">
			<grid className="c-passphrase-grid">
				{rows.map((row, rowIndex) => <row key="{rowIndex}" className="c-passphrase-grid__row">
					{row.map((wordItem) => <cell smCols="{1}" lgCols="{4}" xlCols="{4}" mdCols="{2}" key="{wordItem.word}" className="c-passphrase-grid__cell">
						<div className="c-passphrase-grid__cell-index">
							<div className="c-passphrase-grid__index">
								{wordItem.index + 1}
							</div>
						</div>
						<div className="c-passphrase-grid__cell-word">
							<div className="c-passphrase-grid__word">
								{wordItem.word}
							</div>

						</div>
					</cell>)}
				</row>)}
				<div className="c-passphrase-grid__button-container">
					<copytoclipboard text="{passphrase}" onCopy="{notify}">
						<button>Copie</button>
					</copytoclipboard>
				</div>
			</grid>
		</div>

	);
};

PassphraseGrid.propTypes = {
	passphrase: PropTypes.string
};

export default PassphraseGrid;
