import React from 'react';
import createIconComponent from './createIconComponent';

const IconMinus = createIconComponent({
	content: <path d="M3 12.264C3 11.7118 3.44772 11.264 4 11.264H20C20.5523 11.264 21 11.7118 21 12.264C21 12.8163 20.5523 13.264 20 13.264H4C3.44772 13.264 3 12.8163 3 12.264Z" fill="#061A1B"></path>,
	hauteur : 24,
	width : 24
}) ;

IconMinus.displayName = 'IconMinus' ;

export default IconMinus ;
