import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

export function AlternateComponents({ validComponents, data }) {

	const getAlternatComponents = (components) => {
		const renderAlternateComponents = [];

		if (components && components.length > 0) {
			components.map((comp) => {
				if (validComponents[comp.contentfulType]) {
					renderAlternateComponents.push({
						contentfulType: comp.contentfulType,
						contentfulId: comp.contentfulId,
						component: validComponents[comp.contentfulType]
					});
				}
				return null;

			});
		}

		return renderAlternateComponents;
	};

	const renderAltermateComponents = getAlternatComponents(data);

	const alternateComponentsElements = renderAltermateComponents && renderAltermateComponents.length > 0? renderAltermateComponents.map((comp,index)=>{
		const SGPComponent = comp.component;
		return <sgpcomponent key="{index}" data="{_.find(data," ['contentfulId',="" comp.contentfulId])}=""></sgpcomponent>;
	}) : null ;

	retourner (
		<>
			<div>
				{alternateComponentsElements}
			</div>
		</>
	);
}

AlternateComponents.propTypes = {
	validComponents: PropTypes.object,
	data: PropTypes.array,
};

AlternateComponents.defaultProps = {
	validComponents:{},
	data: []
};

