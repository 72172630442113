import React, { Fragment } from 'react';
import createIconComponent from './createIconComponent';

const IconFilterAll = createIconComponent({
	content: (
		<fragment>
			<g>
				<path d="M20,12V4H4v16h8v8h16V12H20z M5,19V5h14v7h-7v7H5z M27,27H13V13h14V27z"></path>
				<polygon points="24.4,16.3 23.6,15.7 19,22.2 16.4,18.7 15.6,19.3 19,23.8"></polygon>
			</g>
		</fragment>
	),
	hauteur : 32,
	width : 32
}) ;

IconFilterAll.displayName = 'IconFilterAll' ;

export default IconFilterAll ;
