import React, { Fragment } from 'react';
import createIconComponent from './createIconComponent';

const IconRaces = createIconComponent({
	content: (
		<fragment>
			<path d="M28.16 29.44v2.56h-28.16v-2.56h28.16zM11.52 15.36v11.52h-7.68l7.68-11.52zM6.232 25.6h4.008v-6.012l-4.008 6.012zM14.080 0h5.124l0.164 0.113c3.239 2.237 4.952 6.693 4.952 12.687 0 6.32-0.751 10.689-2.373 13.74l-0.18 0.34h-0.385c-3.311-0-4.359-0.002-5.382-0.009-0.568-0.004-0.992-0.010-1.297-0.018l-0.623-0.017v-26.836zM15.36 1.28v24.304c0.191 0.002 0.407 0.005 0.649 0.006 0.977 0.007 1.98 0.009 4.981 0.009 1.386-2.822 2.050-6.911 2.050-12.8 0-5.507-1.502-9.519-4.239-11.52h-3.441z"></path>
		</fragment>
	),
	hauteur : 32,
	width : 32
}) ;

IconRaces.displayName = 'IconRaces' ;

export default IconRaces ;
