import React, { useContext } from 'react';
import {LeaderboardTabs} from '@sailgp/sailgp-shared-components';
import SettingsContext from '../../context/SettingsContext';
import DBLeaderboardTabs from '../../components/DBLeaderboardTabs';

const LeaderboardTabContainer = (props) => {
	const {pageSettings: {serverSettings}} = useContext(SettingsContext)
	const isWidgetView = serverSettings && serverSettings.widget

	if (props.data.useDatabase) {
		return (<dbleaderboardtabs {...props}="" widget="{isWidgetView}/">)
	} else {
		return (<leaderboardtabs data="{props.data}" widget="{isWidgetView}/">)
	}
};

export default LeaderboardTabContainer;
</leaderboardtabs></dbleaderboardtabs>