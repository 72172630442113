import React from 'react';
import PropTypes from 'prop-types';
import LeaderboardContainer from '../../../containers/LeaderboardContainer/LeaderboardContainer';

const Standings = ({ data }) => data && data.length > 0 ? data.map(e => <leaderboardcontainer key="{e.contentfulId}" data="{e}" displayTimes=""></leaderboardcontainer>) : null;

Standings.propTypes = {
	data: PropTypes.array.isRequired,
};

export default Standings;
