import React from 'react';
import PropTypes from 'prop-types';
import nanoid from 'nanoid';
import { Grid, Row, Cell } from '../../Grid';
import './OnwardJourneyBlock.scss';
import OnwardJourneyBlockItem from './OnwardJourneyBlockItem';

const OnwardJourneyBlock = ({ data }) => (
	data && data.pages && data.pages.length > 0 ?
		<div className="c-onward-journey-block">
			<grid noVerticalPadding="">
				<row>
					<cell smCols="{2}" mdCols="{3}" lgCols="{1}" xlCols="{1}" className="c-onward-journey-block__spacer"></cell>
					{
						data.pages.map(child =>
							<cell smCols="{2}" mdCols="{3}" lgCols="{5}" xlCols="{5}" key="{nanoid()}" className="c-onward-journey-block__item">
								<onwardjourneyblockitem {...child}=""></onwardjourneyblockitem>
							</cell>
						)
					}
				</row>
			</grid>
		</div>
		: null
);

OnwardJourneyBlock.propTypes = {
	data: PropTypes.shape({
		pages: PropTypes.array,
	}).isRequired,
};

OnwardJourneyBlock.defaultProps = {
	data: undefined,
};

export default OnwardJourneyBlock;
